// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentcard{
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    border-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/payment/paymentCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+CAA+C;IAC/C,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".paymentcard{\n    width: 100%;\n    background: #FFFFFF 0% 0% no-repeat padding-box;\n    border-radius: 10px;\n    padding: 10px;\n    border-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
