import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { viewPortalUserData } from "../../../../store/actions/accessAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums"
import "./accessManagement.css"


const ViewPortalUserData = (props) => {
    const { userid } = useParams();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Firstname is required'),
        last_name: Yup.string().required('Lastname is required'),
        userid: Yup.string().required('Unique UserID is required'),
        password: Yup.string(),
        address: Yup.string().required('Address is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        phone: Yup.number().required('Phone number is a required').typeError('Phone number can only be a number').test('len', 'Phone number must be greater than  6 digits and less than or equal to 10 digits', val => val?.toString().length > 6 && val?.toString().length <= 10),
        organisation: Yup.string().required('Organisation is required'),
        organisation_type: Yup.string().required('Organisation Type is required'),
        city: Yup.string().required('City  is required'),
        state: Yup.string().required('State/Province  is required'),
        country: Yup.string().required('Country is required'),
        postal_code: Yup.string().required('Zip code is a required').typeError('Zip code can only be a number')
    });

    const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });


    useEffect(() => {
        props.getSingleUserData(userid);
    }, []);

    const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails.data
    // console.log(data)

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setValue('first_name', data.first_name);
                setValue('last_name', data.last_name);
                setValue('userid', data.userid);
                setValue('email', data.email);
                setValue('phone', data.phone);
                setValue('organisation', data.organisation, { shouldValidate: true });
                setValue('country', data.country);
                setValue('city', data.city);
                setValue('state', data.state);
                setValue('organisation_type', data.organisation_type);
                setValue('postal_code', data.postal_code);
                setValue('createdat', data.createdat)
                setValue("address", data.address, { shouldValidate: true });
                // setAddress(data.address)
                register('address', data.address)
                // setNewRole(data.role)
            })

        }
    }, [data])

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="tariffcard mt-4">
                <Card.Text><h3>View Access</h3></Card.Text>
                <form onSubmit={e => e.preventDefault()}>
                    <>
                        <Row>
                            <Col xs={12} md={6} className="mt-3">
                                <div>
                                    <label htmlFor="floatingInputGridFName">First Name</label>
                                    <input disabled type="text" className={` `} name="first_name" id="floatingInputGridFName" placeholder="First Name" {...register("first_name")} />
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="mt-3">
                                <div>
                                    <label htmlFor="floatingInputGridLName">Last Name</label>
                                    <input disabled type="text" className={``} name="last_name" id="floatingInputGridLName" placeholder="Last Name" {...register("last_name")} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridUn">User Name</label>
                                <div className="form-floating">
                                    <input disabled type="text" className={` `} name="userid" id="floatingInputGridUn" placeholder="User ID" {...register("userid")} />
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridPassword">Password</label>
                                <div className="form-floating">
                                    <input disabled type="password" className={`password_field `} id="floatingInputGridPassword" name="password" placeholder="Temporary password" {...register("password")} />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridEmail">Email</label>
                                <input disabled type="email" className={` `} id="floatingInputGridEmail" name="email" placeholder="Email" {...register("email")} />
                            </Col>
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridPhone">Mobile No.</label>
                                <input disabled type="number" className={` `} name="phone" id="floatingInputGridPhone" placeholder="Phone no" {...register("phone")} />
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridOrganisation">Organisation</label>
                                <input disabled type="text" className={` `} id="floatingInputGridOrganisation" name="organisation" placeholder="Organisation" {...register("organisation")} />
                            </Col>
                            <Col xs={12} md={6} className="mt-3">
                                <label htmlFor="floatingInputGridOrganisation_type">Organisation Type</label>
                                <input disabled type="text" className={` `} name="organisation_type" id="floatingInputGridOrganisation_type" placeholder="Organisation Type" {...register("organisation_type")} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <label htmlFor="floatingInputGridaddress">Address</label>
                                <input disabled type="text" className={` `} name="address" id="floatingInputGridaddress" placeholder="Address.... Type" {...register("address")} />
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={3} className="mt-3">
                                <label htmlFor="floatingInputcountry">Country</label>
                                <input
                                    type="text"
                                    className={` `}
                                    name="country"
                                    id="floatingInputcountry"
                                    placeholder="Country"
                                    {...register("country")}
                                    disabled

                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-3">
                                <label htmlFor="floatingInputstate">State/Province</label>
                                <input
                                    type="text"
                                    className={` `}
                                    name="state"
                                    id="floatingInputstate"
                                    placeholder="State / Province"
                                    {...register("state")}
                                    disabled

                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-3">
                                <label htmlFor="floatingInputcity">City</label>
                                <input
                                    type="text"
                                    className={` `}
                                    name="city"
                                    id="floatingInputcity"
                                    placeholder="City"
                                    {...register("city")}
                                    disabled

                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-3">
                                <label htmlFor="floatingInputGridPostalcode">Postal Code</label>
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className={` `}
                                        name="postal_code"
                                        id="floatingInputGridPostalcode"
                                        placeholder="Postal code"
                                        {...register("postal_code")}
                                        disabled
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                    <div className="row d-flex justify-content-between">
                        <div className="col-lg-12 col-sm-12">
                            <Card.Text className="mt-2"><b>{("Roles")}  <span className='mandatory-field'>*</span></b></Card.Text>
                            <div className=''>
                                <div className="form-floating">
                                    <select disabled className={`input_css`} name={`role`} id="floatingSelectGridrole" >
                                        <option value="">{data.role}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="my-3 ml-0">
                        <Col className="pl-0" md={4}>
                            <Card.Title>
                                Privileges
                            </Card.Title>
                        </Col>
                        <Col className="pl-0" md={8}>
                            <Card.Title>
                                Sub-Privileges
                            </Card.Title>
                        </Col>
                    </Row>
                    <>
                        {data && data.access.map((item, index) =>
                            <Row key={index}>
                                <Col lg={4}>
                                    <div className="my_Privilagecontainer">
                                        <div className="custom-control custom-checkbox mt-3">
                                            <input type="checkbox" id={item.parent_key} name="customCheck" className="custom-control-input" readOnly value={item.value} checked />
                                            <label className="custom-control-label" htmlFor={item.parent_key}>{item.value}</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={8} className="subprivilage_column">
                                    <div className="my_SubPrivilagecontainer" id='subcontainer'>
                                        {item.subrole && item.subrole.map((sub_item, index) => (
                                            <div key={index} className="custom-control custom-checkbox pl-lg-5" style={{ paddingLeft: "2.5rem" }}>
                                                <input type="checkbox" id={sub_item.key} name="customCheck" className="custom-control-input" readOnly value={sub_item.value} checked />
                                                <label className="custom-control-label pt-1" htmlFor={sub_item.key} style={{ fontSize: "0.8rem" }}>{sub_item.value}</label>
                                            </div>
                                        )
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </>

                </form>
            </div >

        </Container >
    )

}

const mapStateToProps = function (state) {
    return {
        fetchSingleUserDetails: state.access.viewPortalUserData,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleUserData: (userid) => dispatch(viewPortalUserData(userid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewPortalUserData));