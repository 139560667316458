const initState = {
    isAccessLoading: false,
    cities: '',
    states: '',
    countries: '',
    roles: '',
    privileges: '',
    organization: '',
    subroles: '',
    getNewPrivileges: '',
}

const mainReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ACCESS_LOADING':
            return { ...state, isAccessLoading: true }
        case 'FETCH_ALL_CITIES':
            return { ...state, cities: action.payload, isLoading: true }
        case 'FETCH_ALL_STATES':
            return { ...state, states: action.payload, isLoading: true }
        case 'FETCH_ALL_COUNTRIES':
            return { ...state, countries: action.payload, isLoading: true }
        case 'FETCH_ALL_ROLES':
            return { ...state, roles: action.payload, isLoading: true }
        case 'FETCH_ALL_PRIVILEGES':
            return { ...state, privileges: action.payload, isLoading: true }
        case 'FETCH_ALL_ORGANIZATION':
            return { ...state, organization: action.payload, isLoading: true }
        case 'FETCH_ALL_SUBROLES':
            return { ...state, subroles: action.payload, isLoading: true }
        case 'FETCH_ALL_NEW_PRIVILEGES':
            return { ...state, getNewPrivileges: action.payload, isLoading: true }
        default:
            return state;
    }
}

export default mainReducer;