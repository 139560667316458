import React, { useMemo, useEffect, useState } from "react";
import { connect, ConnectedProps, RootStateOrAny } from "react-redux";
import { Card, Col, Container, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import PureBreadcrumbs from "../../Breadcrums";
import Table from "../../reacttable/table";
import { deleteInverterPricingApi, fetchAllInverterPricingAction } from "../../../../store/actions/modulePricingAction";
import { MdDelete } from "react-icons/md";

type ProjectTransactionData = {
    projectId: string;
    projectName: string;
    email: string;
    date: string;
};

type ViewInverterPricingProps = ConnectedProps<typeof connector>;

const ViewProjectTransaction: React.FC<ViewInverterPricingProps> = (props) => {
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const dummyData: ProjectTransactionData[] = [
        {
            projectId: 'P001',
            projectName: 'Solar Panel Installation',
            email: 'contact@solarcompany.com',
            date: '2024-08-10T12:34:56Z'
        },
        {
            projectId: 'P002',
            projectName: 'Wind Turbine Maintenance',
            email: 'support@windcompany.com',
            date: '2024-08-12T08:20:30Z'
        },
        {
            projectId: 'P003',
            projectName: 'Energy Storage Solution',
            email: 'info@energystorage.com',
            date: '2024-08-15T14:45:00Z'
        },
        {
            projectId: 'P004',
            projectName: 'Hydro Power Plant',
            email: 'services@hydropower.com',
            date: '2024-08-18T16:10:22Z'
        }
    ];

    useEffect(() => {
        props.fetchAllInverterPrice();
    }, []);

    const data = props.list?.data;
    // console.log("data", data);

    // const deleteSingleInverter = (id: string): void => {
    //     // console.log("id", id);
    //     props.deleteInverterApi(id)
    // }
    // useEffect(() => {
    //     if (props.deleteSingleInverterRes && props.deleteSingleInverterRes.statuscode === 200) {
    //         setDeleteSuccess(true);
    //     }
    // }, [props.deleteSingleInverterRes]);

    // useEffect(() => {
    //     if (deleteSuccess) {
    //         alert("Inverter pricing deleted successfully");
    //         setDeleteSuccess(false);
    //         window.location.reload();
    //     }
    // }, [deleteSuccess]);

    const columns = useMemo(
        () => [
            {
                Header: 'Project ID',
                id: 'project_id1',
                accessor: 'projectId'
            },
            {
                Header: 'Project Name',
                accessor: 'projectName'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Date',
                id: 'date',
                accessor: (d: ProjectTransactionData) => moment(d.date).local().format('DD-MM-YYYY')
            },
            // {
            //     Header: 'Actions',
            //     accessor: 'projectId',
            //     cell: ({ value }: { value: string }) => (
            //         <div>
            //             <span>
            //                 <a
            //                     href={`/dashboard/view_project/${value}`}
            //                     rel="tooltip"
            //                     title="View"
            //                 >
            //                     <FaEye />
            //                 </a>
            //             </span>
            //             &nbsp;&nbsp;&nbsp;&nbsp;
            //             {/* Uncomment if needed */}
            //             {/* <span>
            //                 <a
            //                     href={`/dashboard/edit_project/${value}`}
            //                     rel="tooltip"
            //                     title="Edit"
            //                 >
            //                     <FaEdit />
            //                 </a>
            //             </span> */}
            //             {/* &nbsp;&nbsp;&nbsp;&nbsp;
            //             <span>
            //                 <MdDelete style={{ color: 'red' }} onClick={() => deleteProject(value)} />
            //             </span> */}
            //         </div>
            //     ),
            // },
        ],
        []
    );
    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">Total Sales</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>  133
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">By Websites</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>  80
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">By Calls</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>  20
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">By Agents</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>  33
                        </span>
                    </Card>
                </div>

            </div>

            <div className="row pt-5">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">View Project Transactions</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    Loading...
                                    <h3 className="loader">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                                            <Loader
                                                type="ThreeDots"
                                                color="#232959"
                                                height={40}
                                                width={40}
                                            />
                                        </div>
                                    </h3>
                                </div>
                            ) : data && data.length > 0 ? (
                                <div>
                                    <Table data={dummyData} columns={columns} />
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>

        </Container>
    );
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        loading: state.price.isLoading,
        list: state.price.fetchAllInverterPricing,
        deleteSingleInverterRes: state.price.deleteInverterPricing,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllInverterPrice: () => dispatch(fetchAllInverterPricingAction()),
        deleteInverterApi: (id: string) => dispatch(deleteInverterPricingApi(id)),
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ViewProjectTransaction);
