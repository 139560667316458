import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import "./homecard.css";

const MapContainer = (props) => {
  const getCustomIcon = () => {
    return {
      path: props.google.maps.SymbolPath.CIRCLE, // A dummy path for the marker icon
      scale: 0, // We don't want the actual marker to be visible
      fillOpacity: 0, // Hide the marker icon
    };
  };

  return (
    <div>
      <Map
        google={props.google}
        className={"map"}
        zoom={4.5}
        initialCenter={props.center}
      >
        {props.data && props.data.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            icon={getCustomIcon()}
            label={{
              text: '.',
              className: 'pulse-marker', // Apply the CSS for the pulsing effect
            }}
          />
        ))}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ["visualization", "places"]
})(MapContainer);
