import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import Table from "../../reacttable/table";
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import "../../customer/customerCard.css"
import { FaEye } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai"
import { getPortalUserListAction, userApporvalBySuperAdmin } from "../../../../store/actions/accessAction";
import moment from "moment";
import Swal from 'sweetalert2';

function UserApproval(props) {
    useEffect(() => {
        props.getPortalUserList()
    }, [])

    const statusColorMap = {
        Approved: 'Green',
        Pending: 'Blue',
        Rejected: 'Red'
    }

    const aprroveUser = (userid, status) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to Approve this acount?",
            // type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#1e295d',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                props.manageApproval(userid, status);
                window.location.reload();
            }
        });
    }
    const rejectUser = (userid, status) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to Reject this acount?",
            // type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#1e295d',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                props.manageApproval(userid, status);
                window.location.reload();
            }
        });
    }

    const data = props.list && props.list.data.filter(en => en.status === "Pending")
    // console.log(data)

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'first_name'
            },
            {
                Header: 'Last Name',
                accessor: 'last_name'
            },
            {
                Header: 'User ID',
                id: 'useridd',
                accessor: 'userid'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Role',
                accessor: 'role'
            }, {
                Header: 'Organization',
                accessor: 'organisation'
            },
            {
                Header: 'Creation Date',
                id: 'createdat',
                accessor: d => {
                    return moment(d.createdate)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Last Updated',
                id: 'updatedat',
                accessor: d => {
                    return moment(d.last_updated)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                accessor: "status",
                Cell: (props) => {
                    return (
                        <div style={{ color: statusColorMap[props.value], fontWeight: "bold" }}>{props.value}</div>
                    );
                }

            },
            {
                Header: 'Actions',
                accessor: 'userid',
                Cell: (userid) => {
                    // console.log(userid.value)
                    return (
                        <div>
                            <span>
                                <button className="btn-success" onClick={() => aprroveUser(userid.value, "Approved")}> Approve </button>
                            </span>&nbsp;&nbsp;
                            <span>
                                <button className="btn-danger" onClick={() => rejectUser(userid.value, "Rejected")}> Reject </button>
                            </span>

                        </div>

                    );
                },
            }
        ],
        []
    );

    return (
        <Container fluid >
            <Card>
                <Row className="mt-2">
                    <Col>
                        <Card className="customercard">
                            <Row>
                                <Col className="mt-2">
                                    <b> Pending user approvals</b>
                                    <hr />
                                </Col>
                            </Row>
                            <div>
                                {!data.length ?
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="MutatingDots" title={"MutatingDots"} color="#232959" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
                                    :
                                    (<div className="App"> <Table data={data} columns={columns} /></div>)}
                            </div>
                        </Card>
                    </Col>


                </Row>
            </Card>
            <br />
            <br />

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {

        list: state.access.portalUserList,
        approval: state.access.userApprovalBySuperAdmin,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getPortalUserList: () => dispatch(getPortalUserListAction()),
        manageApproval: (userid, status) => dispatch(userApporvalBySuperAdmin(userid, status)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserApproval);
