// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insights {
	content: '\\f144';
	font-family: FontAwesome;
}

.unordered-list .list-item :hover {
	cursor: pointer;
}

.unordered-list .list-item {
	transform: scale(1);
	transition: .3s ease-in-out;
	transform: scale(1);
}


.glassbackground {
	align-items: center;
	flex-direction: column;
	height: 100%;
	background: #ffffff;
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(8.5px);
	-webkit-backdrop-filter: blur(8.5px);
	border-radius: 10px;
	color: #484848;
	width: 100%;
}

.arrow-icon {
	margin-left: auto;
	display: flex;
	align-items: end;
	font-size: 1.5rem;
	color: rgb(0, 0, 0);
}

/* .sub-list-item {
	padding-left: 3rem;
} */`, "",{"version":3,"sources":["webpack://./src/components/dashboard/sidetoolbar/sideicons.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,wBAAwB;AACzB;;AAEA;CACC,eAAe;AAChB;;AAEA;CAEC,mBAAmB;CAEnB,2BAA2B;CAE3B,mBAAmB;AACpB;;;AAGA;CACC,mBAAmB;CACnB,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;CACnB,gDAAgD;CAChD,4BAA4B;CAC5B,oCAAoC;CACpC,mBAAmB;CACnB,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,iBAAiB;CACjB,aAAa;CACb,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;;GAEG","sourcesContent":[".insights {\n\tcontent: '\\f144';\n\tfont-family: FontAwesome;\n}\n\n.unordered-list .list-item :hover {\n\tcursor: pointer;\n}\n\n.unordered-list .list-item {\n\t-webkit-transform: scale(1);\n\ttransform: scale(1);\n\t-webkit-transition: .3s ease-in-out;\n\ttransition: .3s ease-in-out;\n\t-webkit-transform: scale(1.1);\n\ttransform: scale(1);\n}\n\n\n.glassbackground {\n\talign-items: center;\n\tflex-direction: column;\n\theight: 100%;\n\tbackground: #ffffff;\n\tbox-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n\tbackdrop-filter: blur(8.5px);\n\t-webkit-backdrop-filter: blur(8.5px);\n\tborder-radius: 10px;\n\tcolor: #484848;\n\twidth: 100%;\n}\n\n.arrow-icon {\n\tmargin-left: auto;\n\tdisplay: flex;\n\talign-items: end;\n\tfont-size: 1.5rem;\n\tcolor: rgb(0, 0, 0);\n}\n\n/* .sub-list-item {\n\tpadding-left: 3rem;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
