import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums"
import { Bar, Line, Pie } from 'react-chartjs-2';
import "./homecard.css"
import { fetchAllProjectApi } from "../../../store/actions/projectAction";
import Map from "./map";
import ProjectInsightCard from "./projectInsight/projectInsightCard";
import Recentactivites from "./recentActivites/recentactivites";
import { fetchAllLeadAction, fetchInsightLeadCountApi, fetchLeadGraphAction } from "../../../store/actions/leadAction";
import { fetchProjectGraphAction } from "../../../store/actions/customerAction";
import LeadStatusChart from "./leadGraph";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import StatusCard from "./statusCardComponent";

function Home(props) {
  const { name } = JSON.parse(localStorage.getItem('user'));
  const [apiLoaded, setApiLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Define the initMap function that the Google Maps script will call
    window.initMap = () => {
      setApiLoaded(true);
    };

    // Check if the script is already loaded
    if (window.google && window.google.maps) {
      setApiLoaded(true);
    }
  }, []);

  useEffect(() => {
    // props.fetchAllLeadApi();
    props.fetchProjectGraph();
    props.fetchLeadGraph();
    props.fetchAllProject();
    props.fetchLeadCountAction();
  }, [])

  const heatmapData = props.projectList?.data?.map((item) => item.contact_data?.coordinates);
  // console.log("heatmapdata", heatmapData)
  const leadGraphData = props.leadGraphDataRes && props.leadGraphDataRes.data;
  // console.log("leadGraphData: ", leadGraphData)
  const LeadCount = props.fetchLeadCountRes;
  // console.log("leadCount", LeadCount)

  const prospectingCount = LeadCount?.prospecting;
  const contactedCount = LeadCount?.contacted;
  const proposaldeliveredCount = LeadCount?.proposal_delivered;
  const closedLostCount = LeadCount?.closed_Lost;
  const closedWonCount = LeadCount?.closed_Won;
  const totalLeadCount = (prospectingCount + contactedCount + proposaldeliveredCount + closedLostCount + closedWonCount)

  const percentage = (partialValue, totalValue) => {
    // console.log("percentage", Math.round((100 * partialValue) / totalValue));
    return Math.round((100 * partialValue) / totalValue);
  }
  // console.log(totalLeadCount)
  const prospectingCountPercentage = percentage(prospectingCount, totalLeadCount)
  const contactedCountPercentage = percentage(contactedCount, totalLeadCount)
  const proposaldeliveredCountPercentage = percentage(proposaldeliveredCount, totalLeadCount)
  const closedLostCountPercentage = percentage(closedLostCount, totalLeadCount)
  const closedWonCountPercentage = percentage(closedWonCount, totalLeadCount)

  const leadStatusCardData = [
    { title: 'Prospecting', path: 'prospecting', count: prospectingCount, percentage: prospectingCountPercentage, color: '#4a90e2' },
    { title: 'Contacted', path: 'contacted', count: contactedCount, percentage: contactedCountPercentage, color: '#eded37' },
    { title: 'Proposal Delivered', path: 'proposal_delivered', count: proposaldeliveredCount, percentage: proposaldeliveredCountPercentage, color: '#f57c00' },
    { title: 'Closed/Lost', path: 'closed_Lost', count: closedLostCount, percentage: closedLostCountPercentage, color: '#db2121' },
    { title: 'Closed/Won', path: 'closed_Won', count: closedWonCount, percentage: closedWonCountPercentage, color: '#7cd16f' }
  ];

  return (
    <Container fluid>
      <div className="row">
        <div className="col-lg-6 col-sm-12 mt-4">
          <PureBreadcrumbs />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <Card className="herocard">
            <Row className="mt-4">
              <Col xs={12} md={6} style={{ zIndex: "99" }}>
                <span className="heroheading">Hello, {name}!</span>
                <p className="herosubheading">
                  Check live solar data, track your sales, payment and generate insightful reports.
                </p>
                <p className="herosubheading">
                  Hurray!!! You have won {closedWonCount} leads.
                </p>
              </Col>
              <Col xs={12} md={6} className='d-flex justify-content-end'>
                <img className="heroimage" src={process.env.PUBLIC_URL + '/images/homepage/heroImage.svg'} alt="solar" />
              </Col>
            </Row>
          </Card>
        </div>
        {/* <div className="col-lg-1 col-sm-12">
        </div> */}
        <div className="col-lg-5 col-md-12 col-sm-12">
          <span style={{ color: "var(--text-primary)", fontWeight: "600", fontSize: "22px" }}>Project Insight</span>
          <ProjectInsightCard />
        </div>
      </div>

      <Row className="pl-2 mt-3">
        <h5 style={{ color: "var(--text-primary)", fontWeight: "600", fontSize: "22px" }}>
          Lead Insight
        </h5>
      </Row>

      <Row className="status-card-row">
        {leadStatusCardData.map((status) => (
          <Col xs={12} sm={6} md={4} lg={2} className="mt-3 flex-grow-1" key={status.title}>
            <StatusCard
              link={`/dashboard/home/insightLeadCardStatus/${status.path}`}
              title={status.title}
              count={status.count}
              percentage={status.percentage}
              color={status.color}
            />
          </Col>
        ))}
      </Row>

      <div className="row mt-5">
        <div className="col-lg-6 col-sm-12 pad_t1">
          <Card className="graphcard">
            <Card.Header>
              <Row>
                <Col xs={12} md={8}>
                  <b>  <div className="text-left">This Year Growth</div></b>
                </Col>
              </Row>
            </Card.Header>
            <div>
              <LeadStatusChart data={leadGraphData} loading={props.leadGraphLoading} />
            </div>
          </Card>
        </div>
        <div className="col-lg-6 col-sm-12 pad_t1">
          <Card className="graphcard">
            <Card.Header>
              <Row>
                <Col xs={12} md={8}>
                  <b>  <div className="text-left">Recent Project Activities</div></b>
                </Col>
              </Row>
            </Card.Header>
            <div>
              <Recentactivites />
            </div>
          </Card>
        </div>
      </div>

      <Row className="mt-4 mb-4">
        <Col >
          <Card className="heatmapcard">
            <h5 className="p-3">Location heat map
            </h5>
            <Card className="heatmapcardinside">
              {apiLoaded ?
                <Map data={heatmapData} center={{ lat: 20.5937, lng: 78.9629 }} />
                :
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px"
                }}>Map is Loading...</div>
              }
            </Card>
            <br />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    projectGraphLoading: state.customer.isLoading,
    // list: state.lead.fetchAllLead,
    projectGraphDataRes: state.customer.fetchProjectGraph,
    leadGraphLoading: state.lead.isLoading,
    leadGraphDataRes: state.lead.fetchLeadGraph,
    projectList: state.project.fetchAllProject,
    fetchLeadCountRes: state.lead.fetchInsightLeadCount,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    // fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
    fetchProjectGraph: () => dispatch(fetchProjectGraphAction()),
    fetchLeadGraph: () => dispatch(fetchLeadGraphAction()),
    fetchAllProject: () => dispatch(fetchAllProjectApi()),
    fetchLeadCountAction: () => dispatch(fetchInsightLeadCountApi()),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);

