import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import { fetchAllProjectApi } from "../../../../store/actions/projectAction";
import ActivityTable from "./activityTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function RecentActivity(props) {
    useEffect(() => {
        props.fetchAllProject()
    }, [])

    const data = props.list && props.list.data?.slice(0, 10)
    // console.log("data",data)
    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'contact_data.firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'contact_data.lastname'
            },
            {
                Header: 'Email',
                accessor: 'contact_data.email'
            },
            {
                Header: 'Mobile',
                accessor: 'contact_data.mobile'
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ],
        []
    );

    return (
        <Container fluid >

            <div>
                {props.loading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Loading...
                        <h3 className="loader">
                            <Loader type="ThreeDots" color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
                        </h3>
                    </div>
                ) : data && data.length > 0 ? (
                    <div className="App">
                        <ActivityTable data={data} columns={columns} />
                        <p className='ml-2' >
                            <Link className='view-more' to="/dashboard/view_project"><b>...{("View More")}</b></Link>
                        </p>
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "340px" }}>
                        <h3>No Data Found</h3>
                    </div>
                )}
            </div>

            <br />
            <br />

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.project.isLoading,
        list: state.project.fetchAllProject,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllProject: () => dispatch(fetchAllProjectApi()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentActivity);

