import { ADMIN_BASE_URL, FETCH_ALL_COUNTRIES_URL, FETCH_ALL_STATES_URL, FETCH_ALL_CITIES_URL, FETCH_ALL_ROLES_URL, FETCH_ALL_PRIVILEGES_URL, FETCH_ALL_ORGANIZATION, FETCH_ALL_SUBROLES_URL, FETCH_NEW_PRIVILEGES_API } from "../../components/config/config";

export const fetchCoutryAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_COUNTRIES_URL, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(resp => resp.json())
            .then(data => {
                //  console.log(" FETCH_ALL_COUNTRIES----",data);
                dispatch({ type: 'FETCH_ALL_COUNTRIES', payload: data })
            })
    }
}

export const fetchStateAction = (cc) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_STATES_URL + "?country_code=" + cc, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(" FETCH_ALL_STATES----",data);
                dispatch({ type: 'FETCH_ALL_STATES', payload: data })
            })
    }
}

export const fetchCityAction = (sn) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_CITIES_URL + "?state_code=" + sn, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(resp => resp.json())
            .then(data => {
                dispatch({ type: 'FETCH_ALL_CITIES', payload: data })
            })
    }
}

export const fetchRoleAction = (role) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_ROLES_URL, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(" roleAction----", data);
                dispatch({ type: 'FETCH_ALL_ROLES', payload: data })
            })
    }
}

export const fetchPrivilegeAction = (role) => {
    // console.log("rolerole", role)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_PRIVILEGES_URL + "?role_id=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(" privilegeAction----", data);
                dispatch({ type: 'FETCH_ALL_PRIVILEGES', payload: data })
            })
    }
}
export const fetchNewPrivilegeAction = (role) => {
    // console.log("rolerole", role)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_NEW_PRIVILEGES_API + "?role_id=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(" privilegeAction----", data);
                dispatch({ type: 'FETCH_ALL_NEW_PRIVILEGES', payload: data })
            })
    }
}

export const fetchOrganizationAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_ORGANIZATION, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(resp => resp.json())
            .then(data => {
                //  console.log(" FETCH_ALL_COUNTRIES----",data);
                dispatch({ type: 'FETCH_ALL_ORGANIZATION', payload: data })
            })
    }
}

export const fetchSubRolesAction = (privilegekey) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        return fetch(ADMIN_BASE_URL + FETCH_ALL_SUBROLES_URL + "?privilegekey=" + privilegekey, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(resp => resp.json())
            .then(data => {
                //  console.log(" FETCH_ALL_SUBROLES----",data);
                dispatch({ type: 'FETCH_ALL_SUBROLES', payload: data })
            })
    }
}