import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums.js"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import "../customerCard.css"
import { fetchCountryCodeAction } from "../../../../store/actions/projectAction.js";
import { getPortalUserListAction } from "../../../../store/actions/accessAction.js";
import { createLeadAction } from "../../../../store/actions/leadAction.js";


const UpdateContact = (props) => {
    const [address, setAddress] = useState("");

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Phone number is a required').typeError('Phone number is a required'),
    });

    useEffect(() => {
        props.getUserList();
    }, []);

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    // useEffect(() => {
    //     if (data) {
    //         const {
    //             firstname,
    //             lastname,
    //             email,
    //             mobile,
    //             address,
    //             country,
    //             state,
    //             city,
    //             postal_code,
    //             is_residential,
    //             dob,
    //             country_code,
    //             gender,
    //             coordinates: { longitude, latitude } = {},
    //         } = data;

    //         setValue("firstname", firstname);
    //         setValue("lastname", lastname);
    //         setValue("email", email);
    //         setValue("mobile", mobile);
    //         setValue("address", address);
    //         setAddress(address)
    //         setValue("country", country);
    //         setValue("state", state);
    //         setValue("city", city);
    //         setValue("postal_code", postal_code);
    //         setValue("longitude", longitude);
    //         setValue("latitude", latitude);
    //         setValue("isResidential", is_residential);
    //         setValue("countryCode", country_code);
    //         setValue("gender", gender);
    //         setValue("description", description);
    //         setDateOfBirth(dob)
    //         setFormattedDate(dob);
    //     }
    // }, [data, setValue]);

    const handleAddressChange = address => {
        setAddress(address);
    };

    const handleSelect = address => {
        geocodeByAddress(address).then(results =>
            getLatLng(results[0]))
            .then(latLng => {
                const { lat, lng } = latLng;
                const res = latiLongi(lat, lng);
                res.then(respon => {
                    setAddress(address);
                    setValue("address", address);
                    setValue("latitude", lat);
                    setValue("longitude", lng);

                    let city, state, country, postCode;
                    for (let i = 0; i < respon.results[0].address_components.length; i++) {
                        for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                            switch (respon.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = respon.results[0].address_components[i].long_name;
                                    setValue("city", city);
                                    break;
                                case "administrative_area_level_1":
                                    state = respon.results[0].address_components[i].long_name;
                                    setValue("state", state);
                                    break;
                                case "country":
                                    country = respon.results[0].address_components[i].long_name;
                                    setValue("country", country);
                                    break;
                                case "postal_code":
                                    postCode = respon.results[0].address_components[i].long_name;
                                    setValue("postal_code", postCode);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                });
            });
    };

    const onSubmit = (data) => {
        console.log("data", data);
        // props.saveLead(data, formattedDate);
    };

    // if (props.saveLeadRes && props.saveLeadRes.statuscode === 200) {
    //     alert("Lead has been created successfully");
    //     window.location.reload();
    //     // return <Redirect to='/dashboard/view_project' />;
    // }
    // if (props.saveLeadRes && props.saveLeadRes.statuscode === 405) {
    //     alert(props.saveLeadRes.error);
    //     window.location.reload();
    //     // return <Redirect to='/dashboard/view_project' />;
    // }

    // console.log("error", errors);
    // console.log("user list", props.list && props.list.data);

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="mb-2">
                <Col className="mt-3">
                    <h4>Update Contact</h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <Card className="tariffcard">
                            <Card.Body>
                                <Row className="py-2">
                                    <Col>
                                        <Card.Title>
                                            <span>Customer</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.firstname ? 'is-invalid' : ''}`}
                                                    name="firstname"
                                                    placeholder="Firstname"
                                                    {...register("firstname")}
                                                />
                                                <div className="invalid-feedback">{errors.firstname?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.lastname ? 'is-invalid' : ''}`}
                                                    name="lastname"
                                                    placeholder="Lastname"
                                                    {...register("lastname")}
                                                />
                                                <div className="invalid-feedback">{errors.lastname?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.email ? 'is-invalid' : ''}`}
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.mobile ? 'is-invalid' : ''}`}
                                                    name="mobile"
                                                    placeholder="Mobile number"
                                                    {...register("mobile")}
                                                />
                                                <div className="invalid-feedback">{errors.mobile?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <Card.Title>
                                                    <span>Address Information</span>
                                                </Card.Title>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleAddressChange}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Places ...',
                                                                    className: `location-search-input input_login`
                                                                })}
                                                                name="address"
                                                            />
                                                            {/* <div className="invalid-feedback">{errors.address?.message}</div> */}
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    const style = suggestion.active
                                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="latitude"
                                                    placeholder="Latitude"
                                                    {...register("latitude")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.latitude?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="longitude"
                                                    placeholder="Longitude"
                                                    {...register("longitude")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.longitude?.message}</div> */}
                                            </Col>
                                    
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="city"
                                                    placeholder="City"
                                                    {...register("city")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.city?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="state"
                                                    placeholder="State/Province"
                                                    {...register("state")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.state?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="country"
                                                    placeholder="Country"
                                                    {...register("country")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.country?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="postal_code"
                                                    placeholder="Postal Code"
                                                    {...register("postal_code")}
                                                />
                                                {/* <div className="invalid-feedback">{errors.postal_code?.message}</div> */}
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center">
                                            {/* <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start">
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    window.location.reload();
                                                }}> {("RESET")} </button>
                                            </Col> */}
                                            <Col lg={12} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2'>
                                                {props.loading ?
                                                    <button className="lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {("Saving...")}
                                                    </button>
                                                    :
                                                    <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>{("UPDATE CONTACT")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form >
        </Container >
    );
};

const mapStateToProps = (state) => ({
    loading: state.customer.isLoading,
    list: state.access.portalUserList,
    // saveLeadRes: state.lead.createNewLead,
});

const mapDispatchToProps = (dispatch) => ({
    getUserList: () => dispatch(getPortalUserListAction()),
    // saveLead: (data) => dispatch(createLeadAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContact);
const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}