import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from 'react-bootstrap/esm/Switch';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardLayout from "./components/dashboard/layout";
import AuthComponent from "./components/auth/login/Login";
import ChangePassword from "./components/auth/changePass";
import Forget_Otp from "./components/auth/forgetPassword/forget_otp";
import ForgetPassword from "./components/auth/forgetPassword/forget_password";
import Geocode from "react-geocode";

// console.log("geocode", Geocode);

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

// Private Route
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
)

function App(props) {
  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Route exact path="/" component={AuthComponent} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <Route exact path="/forget_password" component={ForgetPassword} />
        <Route exact path="/forget_otp" component={Forget_Otp} />
        <PrivateRoute path="/dashboard" component={DashboardLayout} />
      </Switch>
    </div>

  )
}


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.user
  }
}
export default connect(mapStateToProps)(App)