import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Row } from 'react-bootstrap';


const PureBreadcrumbs = ({ breadcrumbs }) => (
  <div className="d-flex">
    {breadcrumbs.slice(1).map(({ breadcrumb, match }, index) => (
      <div className="bc" key={match.url}>
        <Link to={match.url || "/dashboard"} style={{ fontSize: "13px", color: "var(--text-primary)" }}> {breadcrumb} </Link>
        {index < breadcrumbs.slice(1).length - 1 && " / "}
      </div>
    ))}

  </div>
);


export default withBreadcrumbs()(PureBreadcrumbs);