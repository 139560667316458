import { combineReducers } from 'redux';
import authReducer from './authReducer';
import projectReducer from './projectReducer';
import mainReducer from './mainReducer';
import accessReducer from './accessReducer';
import customerReducer from './customerReducer';
import leadReducer from './leadReducer';
import modulePricingReducer from './modulePricingReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    lead: leadReducer,
    main: mainReducer,
    access: accessReducer,
    customer: customerReducer,
    price: modulePricingReducer,
});

export default rootReducer;