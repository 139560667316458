import React from 'react'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { connect } from "react-redux";
import PureBreadcrumbs from "../../Breadcrums";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { createEmailCampaignAction } from '../../../../store/actions/leadAction';


function CreateEmailCampaign(props) {
    const [showDateWarning, setShowDateWarning] = useState(false);


    const smsSchema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        recipients: Yup.string().required('Recipients is required'),
        subject: Yup.string().required('Subject is required'),
    });

    const [startDate, setStartDate] = useState(null);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(smsSchema) });

    const onSubmit = async data => {
        if (startDate === null) {
            setShowDateWarning(true)
        } else {
            setShowDateWarning(false)
            // console.log("data", data, startDate);
            props.createEmail(data, startDate)
        }
    }


    if (props.saveEmailRes.statuscode === 200) {
        alert("Email has been created");
        window.location.reload();
    }


    return (
        <div className='container-fluid'>
            <Row>
                <Col lg={12}>
                    <PureBreadcrumbs />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col lg={12}>
                    <Card className='tariffcard pb-5'>
                        <b>Create Email Details</b>
                        <Card.Body>
                            <form>
                                <label htmlFor="floatingSelectGridU">Select Recipients</label>
                                <div className="form-floating">
                                    <select autoFocus className={`input_css ${errors.unit ? 'is-invalid' : ''}`} name="recipients" id="floatingSelectGridU" {...register("recipients")}>
                                        <option value="" disabled>Select Recipients</option>
                                        <option value="All">All</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.recipients?.message}</div>
                                </div><br />

                                <label htmlFor='floatingInputGridSubject'>Email Subject <span className='mandatory-field'>*</span></label>
                                <div className="form-floating">
                                    <input type="description" className={` ${errors.subject ? 'is-invalid' : ''}`} name="subject" id="floatingInputGridSubject" placeholder="Enter Email subject" {...register("subject")} />
                                    <div className="invalid-feedback">{errors.subject?.message}</div>
                                </div><br />

                                <label>Email Message <span className='mandatory-field'>*</span></label>
                                <div className="form-floating">
                                    <input type="description" className={` ${errors.message ? 'is-invalid' : ''}`} name="message" id="floatingInputGridmessage" placeholder="Message" {...register("message")} />
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                </div><br />

                                <div className="form-floating">
                                    <Row>
                                        {/* <Col md={4}>
                                        </Col> */}
                                        <Col md={4}>
                                            Select Date <span className='mandatory-field'>*</span> :
                                            <DatePicker
                                                className='gray-border ml-3'
                                                selected={startDate}
                                                name="start"
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText='Select Date'
                                            />
                                            {showDateWarning &&
                                                <small className="text-danger d-flex justify-content-center pl-3">Please select the date</small>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="mt-5 d-flex justify-content-end">
                                    <Col className=''>
                                        <button className="lgn-btn" type="button" onClick={() => {
                                            window.location.reload();
                                        }}>RESET</button>
                                    </Col>
                                    <Col lg={6} className='d-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>CREATE EMAIL CAMPAIGN</button>
                                        }

                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        saveEmailRes: state.lead.createEmailCampaign,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        createEmail: (data, startDate) => dispatch(createEmailCampaignAction(data, startDate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailCampaign)
