import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { fetchSingleBatteryPricingAction } from "../../../../store/actions/modulePricingAction.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";


interface SingleBatteryPricingData {
    manufacturer: string;
    sku: string;
    total_energy: string;
    max_continuous_power: string;
    efficiency: string;
    price_per_battery: string;
    battery_additional_charges: string;
}


const validationSchema = Yup.object().shape({
    manufacturer: Yup.string().required('manufacturer is required'),
    sku: Yup.string().required('Stock Keeping Unit is required'),
    total_energy: Yup.string().required('Total energy is required').typeError("Total energy is required"),
    max_continuous_power: Yup.string().required('Max continous power is required').typeError("Max continous power is required"),
    efficiency: Yup.string().required('Efficiency is required').typeError("Efficiency is required"),
    price_per_battery: Yup.string().required('Price/Battery is required').typeError("Price/Battery is required"),
    battery_additional_charges: Yup.string().required('Battery additional charge is required').typeError("Battery additional charge is required"),
});

const ViewSingleBatteryPricing: React.FC<PropsFromRedux> = (props) => {
    const { id } = useParams()
    // console.log("props", props);

    const { register, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    useEffect(() => {
        props.fetchSinglePricing(id);
    }, [])

    const singleData: SingleBatteryPricingData | undefined = props.fetchSingleRes?.data;
    // console.log("singleData", singleData)

    useEffect(() => {
        if (singleData) {
            const {
                manufacturer = '',
                sku = '',
                total_energy = '',
                max_continuous_power = '',
                efficiency = '',
                price_per_battery = '',
                battery_additional_charges = ''
            } = singleData;
            setValue("manufacturer", manufacturer)
            setValue("sku", sku)
            setValue("total_energy", total_energy)
            setValue("max_continuous_power", max_continuous_power)
            setValue("efficiency", efficiency)
            setValue("price_per_battery", price_per_battery)
            setValue("battery_additional_charges", battery_additional_charges)
        }
    }, [singleData, setValue])


    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>View Single Battery Pricing</h4>
                </Col>
            </Row>
            <form  >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>View Single Battery Pricing</span>
                                        </Card.Title>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="title">Manufacturer</label>
                                                <input
                                                    type="text"
                                                    className={`input_login `
                                                    }
                                                    id="manufacturer"
                                                    placeholder="manufacturer"
                                                    {...register("manufacturer")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="technology">Stock Keeping Unit</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="sku"
                                                    placeholder="Stock Keeping Unit"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("sku")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                            <label htmlFor="total_energy" >Total Energy</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="total_energy"
                                                    placeholder="Total Energy"
                                                    {...register("total_energy")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={4}>
                                            <label htmlFor="max_continuous_power" >Max Continous Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="max_continuous_power"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Max Continuous Power"
                                                    {...register("max_continuous_power")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={4} >
                                            <label htmlFor="efficiency" >Efficiency</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="efficiency"
                                                    placeholder="Efficiency"
                                                    {...register("efficiency")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            < Col md={6} >
                                            <label htmlFor="price_per_battery">Price/Battery</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="price_per_battery"
                                                    placeholder="Price/Battery"
                                                    {...register("price_per_battery")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={6}>
                                            <label htmlFor="battery_additional_charges">Battery Additional Charge</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="battery_additional_charges"
                                                    placeholder="Battery Additional Charge"
                                                    {...register("battery_additional_charges")}
                                                    disabled
                                             />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    fetchSingleRes: state.price.fetchSingleBatteryPricing,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchSinglePricing: (id: number) => dispatch(fetchSingleBatteryPricingAction(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewSingleBatteryPricing);
