import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums.js"
import "react-datepicker/dist/react-datepicker.css";
import "../customerCard.css"
import { getPortalUserListAction } from "../../../../store/actions/accessAction.js";
import { createContactAction, validateContactEmailAction } from "../../../../store/actions/customerAction.js";


const CreateContact = (props) => {
    const [address, setAddress] = useState("");
    const [apiLoaded, setApiLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Define the initMap function that the Google Maps script will call
        window.initMap = () => {
            setApiLoaded(true);
        };

        // Check if the script is already loaded
        if (window.google && window.google.maps) {
            setApiLoaded(true);
        }
    }, []);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Phone number is a required').typeError('Phone number is a required'),
        address: Yup.string().required('Address is required'),
        latitude: Yup.number().required('Latitude is required').typeError('Latitude must be a number'),
        longitude: Yup.number().required('Longitude is required').typeError('Longitude must be a number'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        postal_code: Yup.string().required('Postal code is required'),
    });

    useEffect(() => {
        props.getUserList();
    }, []);

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });


    const handleEmailValidate = (e) => {
        const value = e.target.value;
        // console.log("value", value)
        if (value) {
            props.validateContactEmail(value);
        }
    };

    // Geolocation placeAutoComplete logic
    const handleSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            if (!results || results.length === 0) {
                throw new Error('No results found');
            }

            const latLng = await getLatLng(results[0]);
            const { lat, lng } = latLng;

            const res = await latiLongi(lat, lng);
            if (!res || !res.results || res.results.length === 0) {
                throw new Error('No response from latiLongi');
            }

            setError('')
            setAddress(address);
            setValue("address", address);
            setValue("latitude", lat);
            setValue("longitude", lng);

            let city, state, country, postCode;
            res.results[0].address_components.forEach((component) => {
                component.types.forEach((type) => {
                    switch (type) {
                        case "locality":
                            city = component.long_name;
                            setValue("city", city);
                            break;
                        case "administrative_area_level_1":
                            state = component.long_name;
                            setValue("state", state);
                            break;
                        case "country":
                            country = component.long_name;
                            setValue("country", country);
                            break;
                        case "postal_code":
                            postCode = component.long_name;
                            setValue("postal_code", postCode);
                            break;
                        default:
                            break;
                    }
                });
            });
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to retrieve location details. Please try again.');
        }
    };

    const onSubmit = (data) => {
        // console.log("data", data, formattedDate);
        props.saveContact(data);
    };

    if (props.saveContactRes && props.saveContactRes.statuscode === 200) {
        alert("Contact has been created successfully");
        window.location.reload();
    }
    if (props.saveContactRes && props.saveContactRes.statuscode === 405) {
        alert(props.saveContactRes.error);
        window.location.reload();
    }



    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="mb-2">
                <Col className="mt-3">
                    <h4>Create New Contact</h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <Card className="tariffcard">
                            <Card.Body>
                                <Row className="py-2">
                                    <Col>
                                        <Card.Title>
                                            <span>Customer</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.email ? 'is-invalid' : ''}`}
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                    onChange={handleEmailValidate}
                                                />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                                {props.validateContactEmailRes?.statuscode === 200 && <div className="text-danger">{props.validateContactEmailRes?.result}</div>}

                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.mobile ? 'is-invalid' : ''}`}
                                                    name="mobile"
                                                    placeholder="Mobile number"
                                                    {...register("mobile")}
                                                />
                                                <div className="invalid-feedback">{errors.mobile?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.firstname ? 'is-invalid' : ''}`}
                                                    name="firstname"
                                                    placeholder="Firstname"
                                                    {...register("firstname")}
                                                />
                                                <div className="invalid-feedback">{errors.firstname?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.lastname ? 'is-invalid' : ''}`}
                                                    name="lastname"
                                                    placeholder="Lastname"
                                                    {...register("lastname")}
                                                />
                                                <div className="invalid-feedback">{errors.lastname?.message}</div>
                                            </Col>
                                        </Row>


                                        { /*Address */}
                                        <Row className="mt-4">
                                            <Col md={12}>
                                                <Card.Title>
                                                    <span>Address Information</span>
                                                </Card.Title>
                                                {error && <div className="alert alert-danger">{error}</div>}
                                                {apiLoaded ? (
                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={(value) => setAddress(value)}
                                                        onSelect={handleSelect}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: `location-search-input ${errors.address ? 'is-invalid' : ''}`
                                                                    })}
                                                                    name="address"
                                                                />
                                                                <div className="invalid-feedback">{errors.address?.message}</div>
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}

                                                                    {suggestions.map((suggestion, index) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                ) : (
                                                    <div>Loading Google Maps...</div>
                                                )}
                                            </Col>
                                        </Row>
                                        { /*Address */}

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.latitude ? 'is-invalid' : ''} `}
                                                    name="latitude"
                                                    placeholder="Latitude"
                                                    {...register("latitude")}
                                                />
                                                <div className="invalid-feedback">{errors.latitude?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.longitude ? 'is-invalid' : ''} `}
                                                    name="longitude"
                                                    placeholder="Longitude"
                                                    {...register("longitude")}
                                                />
                                                <div className="invalid-feedback">{errors.longitude?.message}</div>
                                            </Col>
                                          
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.city ? 'is-invalid' : ''}`}
                                                    name="city"
                                                    placeholder="City"
                                                    {...register("city")}
                                                />
                                                <div className="invalid-feedback">{errors.city?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.state ? 'is-invalid' : ''}`}
                                                    name="state"
                                                    placeholder="State/Province"
                                                    {...register("state")}
                                                />
                                                <div className="invalid-feedback">{errors.state?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.country ? 'is-invalid' : ''}`}
                                                    name="country"
                                                    placeholder="Country"
                                                    {...register("country")}
                                                />
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login  ${errors.postal_code ? 'is-invalid' : ''}`}
                                                    name="postal_code"
                                                    placeholder="Postal Code"
                                                    {...register("postal_code")}
                                                />
                                                <div className="invalid-feedback">{errors.postal_code?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center">
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start">
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    window.location.reload();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            <Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2'>
                                                {props.loading ?
                                                    <button className="lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {("Saving...")}
                                                    </button>
                                                    :
                                                    <button className="lgn-btn" type="submit" disabled={props.validateContactEmailRes?.statuscode === 200} onClick={handleSubmit(onSubmit)}>{("CREATE NEW CONTACT")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form >
        </Container >
    );
};

const mapStateToProps = (state) => ({
    loading: state.customer.isLoading,
    list: state.access.portalUserList,
    saveContactRes: state.customer.createContact,
    validateContactEmailRes: state.customer.validateContactEmail,
});

const mapDispatchToProps = (dispatch) => ({
    getUserList: () => dispatch(getPortalUserListAction()),
    saveContact: (data) => dispatch(createContactAction(data)),
    validateContactEmail: (id) => dispatch(validateContactEmailAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContact);
const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}