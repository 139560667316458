import React from "react";
import { Container } from 'react-bootstrap';
import PaymentTable from "./paymentTable";
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"



function PaymentList(props) {
  const data = React.useMemo(
    () => [
      {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      },
      {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      }, {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      }, {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      }, {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      }, {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      }, {
        col1: '#5248231',
        col2: '07-11-2022/15:20PM',
        col3: 'Joe',
        col4: 'Doe',
        col5: 'Joe.Doe@dummy.com',
        col6: 'Completed',
      },
      {
        col1: '#5225252',
        col2: '23-10-2022/10:10AM',
        col3: 'Jorge',
        col4: 'Richards',
        col5: 'Jorge.Richards@dummy.com',
        col6: 'Pending',
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID Invoice',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Date',
        accessor: 'col2',
      },
      {
        Header: 'First Name',
        accessor: 'col3', // accessor is the "key" in the data
      },
      {
        Header: 'Phone',
        accessor: 'col4', // accessor is the "key" in the data
      },
      {
        Header: 'Email',
        accessor: 'col5', // accessor is the "key" in the data
      },
      {
        Header: 'Status',
        accessor: 'col6', // accessor is the "key" in the data
      },
    ],
    []
  )
  return (
    <Container fluid >

      <div>
        {!data.length ?
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading<h3 className="loader"><Loader type="bubble-loop" title={"bubble-loop"} color="#232959" height="40" width="40" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /></h3></div>)
          :
          (<div className="App"> <PaymentTable data={data} columns={columns} /></div>)}
      </div>

      <br />
      <br />

    </Container>
  )

}

export default PaymentList

