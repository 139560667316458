import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, Marker, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import '../newProject/createProject.css';
import { Col, Row } from 'react-bootstrap';
import html2canvas from 'html2canvas';

const containerStyle = {
    width: '100%',
    height: '600px',
};

const libraries = ['places'];

const UpdateProposalMap = ({
    solarPanels,
    setSolarPanel,
    onAddSolarPanels,
    onDeleteSolarPanel,
    isGridEnabled,
    onToggleGridMode,
    onDeleteAllPanels,
    mapCenter,
    mapZoom,
    onCenterChange,
    onZoomChange,
    address,
    setMapAddress,
    formStep,
    singleSolarData,
    setHandleCaptureClickRef,
    setIsCapturing,
    onDesignSaved,
    setIsDesignSaved,
    project_title
}) => {
    const [map, setMap] = useState(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [orientationMode, setOrientationMode] = useState('landscape');
    const mapRef = useRef(null);
    const [initialCoordinatesRender, setInitialCoordinatesRender] = useState(true);

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    // Debounce function to limit the rate of updates
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleInputChange = (e) => {
        setMapAddress(e.target.value);
    };

    useEffect(() => {
        if (isLoaded && mapLoaded && solarPanels.length > 0) {
            const { lat, lng } = solarPanels[0]?.panel_coordinates || {};
            onCenterChange({ lat, lng });
            setInitialCoordinatesRender(false);
        }
    }, [formStep, solarPanels, isLoaded, mapLoaded]);

    const handleCaptureClick = useCallback(() => {
        if (mapRef.current) {
            setIsCapturing(true); // Trigger loading overlay
            const controlButtons = document.querySelectorAll('.custom-map-controls');
            controlButtons?.forEach(button => button.style.display = 'none');

            map?.setOptions({
                fullscreenControl: false,
            });

            html2canvas(mapRef.current, { useCORS: true }).then((canvas) => {
                controlButtons.forEach(button => button.style.display = 'block');
                map?.setOptions({
                    fullscreenControl: true,
                });
                localStorage.removeItem('imgData');
                const imgData = canvas.toDataURL('image/png', 1.0);
                localStorage.setItem('imgData', imgData);

                // Notify parent that the design has been saved
                onDesignSaved();
                // Simulate delay for capturing the image
                setIsCapturing(false); // Hide loading overlay

            });
        }
    }, [map, setIsCapturing, onDesignSaved]);

    const debouncedHandleCaptureClick = debounce(handleCaptureClick, 300);

    const handleDownloadImage = () => {
        if (mapRef.current) {
            const controlButtons = document.querySelectorAll('.custom-map-controls');
            controlButtons?.forEach(button => button.style.display = 'none');

            map?.setOptions({
                fullscreenControl: false,
            });

            html2canvas(mapRef.current, { useCORS: true }).then((canvas) => {
                controlButtons.forEach(button => button.style.display = 'block');
                map?.setOptions({
                    fullscreenControl: true,
                });

                localStorage.removeItem('imgData');
                const imgData = canvas.toDataURL('image/png', 1.0);
                localStorage.setItem('imgData', imgData);

                // download logic
                const link = document.createElement('a');
                link.href = imgData;
                link.download = 'proposal_design.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            });
        }
    };

    const onLoad = useCallback(
        (map) => {
            setMap(map);
            map.setMapTypeId('satellite');
            map.setZoom(mapZoom);

            map.addListener('tilesloaded', () => {
                setMapLoaded(true); // Map is fully loaded
            });

            if (window.google) {
                createCustomControls(map);
            } else {
                console.error('Google Maps API is not loaded.');
            }
        },
        [mapZoom]
    );



    useEffect(() => {
        const storedArray = JSON.parse(localStorage.getItem('new_solar_panels')) || [];
        // console.log("storedArray", storedArray.length)
        if (isLoaded && mapLoaded && singleSolarData && map) {
            if (storedArray.length === 0) {
                // Local storage is empty initially, so we set singleSolarData.
                // console.log("localStorage is empty, setting singleSolarData initially...");
                setSolarPanel(singleSolarData); // Set initial data
                localStorage.setItem('new_solar_panels', JSON.stringify(singleSolarData)); // Save initial data to localStorage
            }
        }
    }, [singleSolarData, isLoaded, mapLoaded, map]);

    // Whenever solarPanels is updated, store the updated array in localStorage.
    useEffect(() => {
        if (solarPanels.length > 0) {
            // console.log("here solar panels is loaded in local storage...");
            localStorage.setItem('new_solar_panels', JSON.stringify(solarPanels));
        }
    }, [solarPanels]);

    // console.log("solar panels", solarPanels);

    const handleMarkerAdd = (event) => {
        localStorage.removeItem('imgData');
        const newMarker = {
            panel_id: new Date().getTime(),
            panel_coordinates: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            },
            rotation: orientationMode,
        };
        setSolarPanel([...solarPanels, newMarker]);
        setIsDesignSaved(false);
    };

    const handleMarkerDragEnd = (event, markerId) => {
        // console.log("drag event: ", event)
        localStorage.removeItem('imgData');
        setSolarPanel(
            solarPanels?.map((marker) =>
                marker.panel_id === markerId
                    ? { ...marker, panel_coordinates: { lat: event.latLng.lat(), lng: event.latLng.lng() } }
                    : marker
            )
        );
        setIsDesignSaved(false);
    };

    const handleMarkerDeleteClick = (markerId) => {
        localStorage.removeItem('imgData');
        setSolarPanel(solarPanels?.filter((marker) => marker.panel_id !== markerId));
        setIsDesignSaved(false);
    };


    const createCustomControls = (map) => {
        const createButton = (text, onClick) => {
            const button = document.createElement('button');
            button.textContent = text;
            button.classList.add('custom-map-control-button');
            button.addEventListener('click', onClick);
            return button;
        };

        const saveButton = createButton('Download Design', () => {
            handleDownloadImage();
            // Notify parent that the design has been saved
            onDesignSaved();
            // Simulate delay for capturing the image
            setIsCapturing(false); // Hide loading overlay
        });
        const saveDesign = createButton('Save Design', handleCaptureClick);
        const deleteAllButton = createButton('Delete All Panels', () => {
            localStorage.removeItem('imgData');
            onDeleteAllPanels();
            debouncedHandleCaptureClick();
            setIsDesignSaved(false);
        });

        // Create left-side control container
        const leftControlDiv = document.createElement('div');
        const leftControlUI = document.createElement('div');
        leftControlUI.classList.add('custom-map-controls');
        leftControlDiv.appendChild(leftControlUI);
        leftControlUI.appendChild(saveDesign);
        leftControlUI.appendChild(deleteAllButton);

        // Create right-side control container
        const rightControlDiv = document.createElement('div');
        const rightControlUI = document.createElement('div');
        rightControlUI.classList.add('custom-map-controls');
        rightControlDiv.appendChild(rightControlUI);
        rightControlUI.appendChild(saveButton);

        // Add controls to the map
        map.controls[window.google?.maps?.ControlPosition?.TOP_LEFT].push(leftControlDiv);
        map.controls[window.google?.maps?.ControlPosition?.TOP_RIGHT].push(rightControlDiv);
    };


    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div>
            <Row className='my-2'>
                <Col lg={12} md={12}>
                    <Autocomplete
                        onLoad={setAutocomplete}
                        onPlaceChanged={() => {
                            if (autocomplete !== null) {
                                const place = autocomplete.getPlace();
                                if (place.geometry) {
                                    const location = place.geometry.location;
                                    onCenterChange({ lat: location.lat(), lng: location.lng() });
                                    onZoomChange(21);
                                    setMapAddress(place.formatted_address);
                                }
                            }
                        }}
                    >
                        <input
                            type='text'
                            id='address'
                            value={address}
                            onChange={handleInputChange}  // Debounced input handler
                            placeholder='Search for a location'
                        />
                    </Autocomplete>
                </Col>
            </Row>
            <div ref={mapRef}>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        zoom={mapZoom}
                        onClick={handleMarkerAdd}
                        onLoad={onLoad}
                        options={{
                            disableDefaultUI: false,
                            mapTypeControl: false,
                            streetViewControl: false,
                            gestureHandling: 'cooperative',
                            scrollwheel: true,
                        }}
                    >
                        {solarPanels?.map((marker) => (
                            <Marker
                                key={marker.panel_id}
                                position={marker.panel_coordinates}
                                draggable={true}
                                onDragEnd={(event) => handleMarkerDragEnd(event, marker.panel_id)}
                                onClick={() => handleMarkerDeleteClick(marker.panel_id)}
                                icon={{
                                    url: `${process.env.PUBLIC_URL}/images/solar4.png`,
                                    scaledSize: new window.google.maps.Size(13, 19),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(7, 7),
                                }}
                            />
                        ))}
                    </GoogleMap>
                )}
            </div>
        </div>
    );
};

export default React.memo(UpdateProposalMap);
