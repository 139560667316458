import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import "./leadGeneration.css"
import Table from "../../reacttable/table";
import Loader from "react-loader-spinner";
import { fetchAllLeadAction, updateLeadAction, updateLeadStatusAction } from "../../../../store/actions/leadAction";


function ViewLead(props) {
    useEffect(() => {
        props.fetchAllLeadApi();
    }, [])

    const data = props.list && props.list.data;
    // console.log("data", data);
    const NewleadCount = data && data.filter(en => en.lead_status === "prospecting").length;
    const contactedCount = data && data.filter(en => en.lead_status === "contacted").length;
    const proposaldelivered = data && data.filter(en => en.lead_status === "proposal_delivered").length;
    const closedLostCount = data && data.filter(en => en.lead_status === "closed_Lost").length;
    const closedWonCount = data && data.filter(en => en.lead_status === "closed_Won").length;

    const handleStatusChange = (lead_id, e) => {
        const status = e.target.value;
        // console.log("data & status", lead_id, status)
        props.updateLeadStatusApi(lead_id, status);
    };

    if (props.updateStatusLeadRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },
            {
                Header: 'Lead Source',
                accessor: 'leadsource'
            },
            {
                Header: 'Creation Date',
                id: 'createat',
                accessor: d => {
                    return moment(d.createat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                id: 'id1',
                accessor: "lead_id",
                Cell: ({ row }) => {
                    const status = row.original.lead_status;
                    const lead_id = row.original.lead_id;
                    // const lead_id = row.original.;
                    // console.log(row)
                    return (
                        <div>
                            <select className="" onChange={(e) => handleStatusChange(lead_id, e)} >
                                <option value={status} hidden>{status}</option>
                                <option value="">Select Lead Status</option>
                                <option value="prospecting">Prospecting</option>
                                <option value="contacted">Contacted</option>
                                <option value="proposal_delivered">Proposal Delivered</option>
                                <option value="closed_Lost">Closed/Lost</option>
                                <option value="closed_Won">Closed/Won</option>
                            </select>
                        </div >
                    )
                }
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/lead/viewSingleLead/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updateLead/${id.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <Row className=' d-flex flex-row flex-nowrap'>
                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Prospecting</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {NewleadCount ? NewleadCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Contacted</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {contactedCount ? contactedCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Proposal Delivered</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {proposaldelivered ? proposaldelivered : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Closed/Lost</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {closedLostCount ? closedLostCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Closed/Won</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {closedWonCount ? closedWonCount : 0}
                        </span>
                    </Card>
                </Col>
            </Row>
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">Lead List</div></b>
                                </Col>
                                <Col xs={12} md={4}>
                                    <b><div className="text-right">Total Leads : {data && data.length ? data.length : 0}</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            {data && data.length > 0 ?
                                (<div className="App">
                                    <Table data={data} columns={columns} />
                                </div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                    Loading
                                    <h3 className="loader">
                                        <Loader
                                            type="ThreeDots"
                                            color="#232959"
                                            height="40"
                                            width="40"
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
                                    </h3>
                                </div>)
                            }

                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        list: state.lead.fetchAllLead,
        updateStatusLeadRes: state.lead.updateLeadStatus,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateLeadStatusApi: (lead_id, lead_status) => dispatch(updateLeadStatusAction(lead_id, lead_status)),
        fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewLead);

