import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums.js";
import "./finance.css";
// import { HandleKeyPress } from "../reusableComponent/handleKeyPress";
import ReactSelect from "react-select";
import { fetchAllLeadAction } from "../../../store/actions/leadAction.js";
import { HandleKeyPress } from "../reusableComponent/handleKeyPress.js";
import { fetchFinanceNoOfMonthsAction, fetchProjectListWithEmailAction, fetchSingleFinanceAction } from "../../../store/actions/financeAction.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

interface FormValues {
    emailid: string;
    project_title: string;
    project_amount: number;
    payment_type: string;
    final_project_amount: number;
    no_of_months: number;
    is_interest_free: string;
    interest_rate: number;
    payment_due_date: string;
}

type MonthData = {
    key: number;
    value: number;
};

const validationSchema = Yup.object().shape({
    emailid: Yup.string()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.string().nullable(), // Make emailid non-mandatory if 'One-Time'
            otherwise: Yup.string().required('Email Id is required').typeError('Email Id is required'),
        }),
    project_title: Yup.string()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.string().nullable(), // Make project_title non-mandatory if 'One-Time'
            otherwise: Yup.string().required('Project title is required').typeError('Project title is required'),
        }),
    payment_type: Yup.string().required('Payment type is required').typeError("Payment type is required"),
    no_of_months: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().nullable(), // no_of_months can be null if 'One-Time'
            otherwise: Yup.number().required('No. of months is required').typeError("No. of months is required"),
        }),
    project_amount: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().nullable(), // project_amount can be null if 'One-Time'
            otherwise: Yup.number().required('Project amount is required').typeError('Project amount is required'),
        }),
    final_project_amount: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().required('Final project amount is required').typeError('Final project amount is required'),
            otherwise: Yup.number().nullable(),
        }),
    is_interest_free: Yup.string()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.string().nullable(), // is_interest_free can be null if 'One-Time'
            otherwise: Yup.string().required('Is interest free is required').typeError('Is interest free is required'),
        }),
    interest_rate: Yup.number()
        .transform((value, originalValue) => originalValue === '' ? null : value) // Transform empty string to null for number type
        .nullable()
        .when(['payment_type', 'is_interest_free'], {
            is: (payment_type, is_interest_free) => payment_type === 'One-Time' || is_interest_free === 'true',
            then: Yup.number().nullable(), // interest_rate can be null if payment_type is 'One-Time' or is_interest_free is 'true'
            otherwise: Yup.number().required('Interest rate is required').typeError('Interest rate is required'),
        }),
    payment_due_date: Yup.string().required('Payment due date is required').matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Payment due date must be a valid date in the format YYYY-MM-DD'),
});

const ViewSingleFinance: React.FC<PropsFromRedux> = (props) => {
    const { id } = useParams();
    const today = new Date().toISOString().split('T')[0];
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`; // Return in dd/mm/yyyy hh:mm:ss format
    };

    useEffect(() => {
        props.fetchAllLeadApi();
        props.fetchNumberOfMonthsApi();
        props.fetchSingleFinanceApi(id);
    }, [id]);

    // console.log("props", props);
    const { register, handleSubmit, reset, setValue, resetField, control, getValues, watch, formState: { errors, isValid } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all",
    });
    const financeData = watch();
    // console.log("financeData", financeData)
    const isInterestFree = financeData?.is_interest_free;
    const payment_type = financeData?.payment_type;
    const interest_rate = financeData?.interest_rate;
    const project_amount = financeData?.project_amount;
    const singleFinanceData = props.fetchSingleFinanceRes?.data ? props.fetchSingleFinanceRes?.data : {};
    // console.log("singleFinanceData", singleFinanceData)

    useEffect(() => {
        const {
            emailid,
            project_title,
            payment_type,
            no_of_months,
            project_amount,
            final_project_amount,
            is_interest_free,
            interest_rate,
            payment_due_date,
        } = singleFinanceData;

        if (singleFinanceData) {
            // Set values in the form
            if (emailid) {
                props.fetchProjectListApi(emailid);
            }
            setValue('emailid', emailid || '');
            setValue('project_title', project_title);
            setValue('payment_type', payment_type || '');
            setValue('no_of_months', no_of_months || 0);
            setValue('project_amount', project_amount || 0);
            setValue('final_project_amount', final_project_amount || 0);
            setValue('is_interest_free', is_interest_free ? 'true' : 'false');
            setValue('interest_rate', interest_rate || 0);
            setValue('payment_due_date', payment_due_date || '');
        }

    }, [singleFinanceData, setValue]);


    const data = props.list && props.list.data
    // console.log("data", data);

    const options = data && data.map((lead: any) => ({
        value: lead.email,
        label: lead.email
    }));
    const selectedEmail = financeData.emailid;
    const selectedEmailOption = options && options.find(option => option.value === selectedEmail);

    const projectTitleApi = props.fetchProjectListRes && props.fetchProjectListRes.data
    // console.log("data", data);

    const projectTitleOptions = projectTitleApi && projectTitleApi.map((item: any) => ({
        value: item.project_title,
        label: item.project_title
    }));
    const selectedProjectTitle = financeData.project_title;
    // console.log("selectedProjectTitle", selectedProjectTitle)
    const selectedProjectTitleOption = projectTitleOptions && projectTitleOptions.find(option => option.value === selectedProjectTitle);
    // console.log("selectedProjectTitleOption", selectedProjectTitleOption)


    return (
        <Container fluid className="pb-4">
            <div className="row" >
                <div className="col-lg-12 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Create Finance</h4>
                </Col>
            </Row>
            <form onSubmit={(e) => e.preventDefault} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={8}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>Create Finance</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="emailid">Email <span className="mandatory-field">*</span></label>
                                                {/* React Select with React Hook Form */}
                                                <Controller
                                                    name="emailid"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ReactSelect
                                                            {...field}
                                                            options={options ? options : []}
                                                            className={` `}
                                                            placeholder="Email"
                                                            value={selectedEmailOption}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                                }),
                                                            }}
                                                            isDisabled={true}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={6} >
                                                <label htmlFor="project_title">Project Title <span className="mandatory-field">*</span></label>
                                                <Controller
                                                    name="project_title"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ReactSelect
                                                            {...field}
                                                            options={projectTitleOptions ? projectTitleOptions : []}
                                                            // className={` ${errors.emailid ? 'is-invalid' : ''}`}
                                                            placeholder="Project Title"
                                                            value={selectedProjectTitleOption}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                                }),
                                                            }}
                                                            isDisabled={true}

                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="floatingSelectGridPaymentType">{("Payment Type")} <span className='mandatory-field'>*</span></label>
                                                <div className="">
                                                    <select className={`form-select form-control `} id="floatingSelectGridPaymentType" {...register('payment_type')} disabled>
                                                        <option value="">{("Select Payment Type")}</option>
                                                        <option value="One-Time">{("One-Time")}</option>
                                                        <option value="Recurring">{("Recurring")}</option>
                                                    </select>
                                                    <div className="invalid-feedback" > {errors.payment_type?.message} </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridPayment_due_date">{("Payment due date (dd/mm/yyyy)")} <span className='mandatory-field'>*</span></label>
                                                <input
                                                    type="date"
                                                    className={`form-control gray-border`}
                                                    id="floatingInputGridPayment_due_date"
                                                    placeholder={("Date")}
                                                    {...register('payment_due_date', {
                                                        required: 'Date is required',
                                                        validate: {
                                                            correctFormat: (value) => {
                                                                // This is a custom validator to check if the date format is correct.
                                                                const datePattern = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy format
                                                                return datePattern.test(value) || 'Invalid date format';
                                                            },
                                                            validDate: (value) => {
                                                                // Ensure the input is a valid date
                                                                return !isNaN(Date.parse(value)) || 'Invalid date';
                                                            },
                                                        },
                                                    })}
                                                    max={today}
                                                    disabled
                                                />
                                                {/* < div className="invalid-feedback" > {errors.payment_due_date?.message} </div> */}
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            {payment_type === 'Recurring' &&
                                                <Col md={12}>
                                                    <label htmlFor="project_amount">Project Amount <span className="mandatory-field">*</span></label>
                                                    <input
                                                        type="number"
                                                        className={`input_login`}
                                                        id="project_amount"
                                                        placeholder="Project Amount"
                                                        onKeyDown={HandleKeyPress}
                                                        {...register("project_amount")}
                                                        disabled
                                                    />
                                                    {/* <div className="invalid-feedback" > {errors.project_amount?.message} </div> */}
                                                </Col>
                                            }
                                        </Row>


                                        {payment_type === 'Recurring' &&
                                            <Row className="mt-3" >
                                                <Col md={isInterestFree === 'true' ? 6 : 4}>
                                                    <label htmlFor="floatingSelectGridPaymentType">{("No. of months")} <span className='mandatory-field'>*</span></label>
                                                    <div className="">
                                                        <select className={`form-select form-control `} id="floatingSelectGridNoOfMonths" disabled {...register('no_of_months')} >
                                                            <option value="">{("Select No.of Months")}</option>
                                                            {props.fetchNoOfMonthsListRes && props.fetchNoOfMonthsListRes.monthly_data?.map((item: MonthData) => (
                                                                <option key={item.key} value={item.key}>{item.value}</option>
                                                            ))}
                                                        </select>
                                                        {/* <div className="invalid-feedback" > {errors.no_of_months?.message} </div> */}
                                                    </div>
                                                </Col>
                                                <Col md={isInterestFree === 'true' ? 6 : 4}>
                                                    <label htmlFor="floatingSelectGridIs_interest_free">{("Is interest free ?")}</label>
                                                    <div className="">
                                                        <select className={`form-select form-control`} disabled id="floatingSelectGridIs_interest_free"
                                                            {...register('is_interest_free')} >
                                                            <option value="" hidden>{("Is interest free ?")}</option>
                                                            <option value='true'>{("Yes")}</option>
                                                            <option value='false'>{("No")}</option>
                                                        </select>
                                                        {/* <div className="invalid-feedback" > {errors.is_interest_free?.message} </div> */}
                                                    </div>
                                                </Col>
                                                {isInterestFree !== 'true' ?
                                                    < Col md={4} >
                                                        <label htmlFor="interest_rate">Interest Rate in (%) <span className="mandatory-field">*</span></label>
                                                        <input
                                                            type="number"
                                                            className={`input_login`}
                                                            id="interest_rate"
                                                            placeholder="Interest Rate"
                                                            {...register("interest_rate")}
                                                            disabled
                                                        />
                                                        {/* <div className="invalid-feedback" > {errors.interest_rate?.message} </div> */}
                                                    </Col>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </Row>
                                        }

                                        <Row className="mt-3" >
                                            <Col md={12} >
                                                <label htmlFor="interest_rate">Final Project Amount <span className="mandatory-field">*</span></label>
                                                < input
                                                    type="number"
                                                    className={`input_login`}
                                                    id="final_project_amount"
                                                    placeholder="Final project amount"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("final_project_amount")}
                                                    disabled
                                                />
                                                {/* < div className="invalid-feedback" > {errors.final_project_amount?.message} </div> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="tariffcard shadow-sm border-0 rounded" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {/* <Card.Body> */}
                            <Card.Title className="text-center mb-4">
                                <Card.Header as="h5" className="text-primary">Activity Tab</Card.Header>
                                {/* <span className="h5 text-primary">Activity Tab</span> */}
                            </Card.Title>
                            {singleFinanceData?.finance_updates && singleFinanceData?.finance_updates.length > 0 ? (
                                singleFinanceData?.finance_updates?.map((update, index) => (
                                    <div
                                        key={index}
                                        className="update-info row mb-3 p-3 rounded bg-light align-items-center"
                                    >
                                        <div className="col-12 col-md-6 text-md-start text-center mb-2 mb-md-0">
                                            <h6 className="mb-1 text-dark">Updated By:</h6>
                                            <span className="text-muted">{update?.updated_by}</span>
                                        </div>
                                        <div className="col-12 col-md-6 text-md-end text-center">
                                            <h6 className="mb-1 text-dark">Updated At:</h6>
                                            <span className="text-muted">{formatDateTime(update?.updatedat)}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center text-muted">No updates available</p>
                            )}

                            {/* </Card.Body> */}
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.finance.isLoading,
    list: state.lead.fetchAllLead,
    fetchNoOfMonthsListRes: state.finance.fetchNoOfMonthsList,
    fetchProjectListRes: state.finance.fetchProjectList,
    fetchSingleFinanceRes: state.finance.fetchSingleFinance,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
    fetchNumberOfMonthsApi: () => dispatch(fetchFinanceNoOfMonthsAction()),
    fetchProjectListApi: (email: string) => dispatch(fetchProjectListWithEmailAction(email)),
    fetchSingleFinanceApi: (id: string) => dispatch(fetchSingleFinanceAction(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewSingleFinance);
