import React, { useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums"
import "./campaignCard.css"
import { Bar } from 'react-chartjs-2';
import { connect } from "react-redux";
import { fetchAllEmailAction, fetchAllSmsAction, fetchCampaignGraphAction } from "../../../store/actions/leadAction";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import Loader from "react-loader-spinner";
import Table from "../reacttable/table";
import CampaignChart from "./campaignGraph";


// export const options = {
//     responsive: true,
//     plugins: {
//         //   legend: {
//         //     position: 'top' as const,
//         //   },
//     },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Growth',
//             data: labels.map(() => Math.random() * 10000),
//             backgroundColor: 'rgba(255, 0, 0, 1)',
//         },
//         {
//             label: 'Decline',
//             data: labels.map(() => Math.random() * 10000),
//             backgroundColor: 'rgba(134, 212, 66, 1)',
//         },
//     ],
// };

function Campaign(props) {

    useEffect(() => {
        props.fetchAllEmail();
        props.fetchAllSms();
        props.fetchCampaignGraphData();
    }, [])

    const emailData = props.fetchAllEmailRes && props.fetchAllEmailRes.data
    const smsData = props.fetchAllSmsRes && props.fetchAllSmsRes.data
    const graphData = props.fetchCampaignGraphRes && props.fetchCampaignGraphRes.data
    // console.log("graphData", graphData)

    const emailColumns = useMemo(

        () => [
            {
                Header: 'Recipients',
                accessor: 'recipients'
            },
            {
                Header: 'Subject',
                accessor: 'subject'
            },
            {
                Header: 'Message',
                accessor: 'message'
            },
            {
                Header: 'Creation Date',
                id: 'createdat',
                accessor: d => {
                    return moment(d.date)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            // {
            //     Header: "Actions",
            //     accessor: "id",
            //     Cell: (id) => {
            //         return (
            //             <div>
            //                 <span>
            //                     <a
            //                         href={`/dashboard/view_campaign/viewSingleEmailCampaign/${id.value}`}
            //                         rel="tooltip"
            //                         title="View"
            //                     >
            //                         <FaEye />
            //                     </a>
            //                 </span>
            //             </div>
            //         );
            //     },
            // },
        ],
        []
    );


    const smsColumns = useMemo(

        () => [
            {
                Header: 'Recipients',
                accessor: 'recipients'
            },
            {
                Header: 'Title',
                accessor: 'title'
            },
            {
                Header: 'Message',
                accessor: 'message'
            },
            {
                Header: 'Creation Date',
                id: 'createdat',
                accessor: d => {
                    return moment(d.createdat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            // {
            //     Header: "Actions",
            //     accessor: "sms_campaign_id",
            //     Cell: (sms_campaign_id) => {
            //         return (
            //             <div>
            //                 <span>
            //                     <a
            //                         href={`/dashboard/view_campaign/viewSingleSmsCampaign/${sms_campaign_id.value}`}
            //                         rel="tooltip"
            //                         title="View"
            //                     >
            //                         <FaEye />
            //                     </a>
            //                 </span>
            //             </div>
            //         );
            //     },
            // },
        ],
        []
    );

    return (
        <Container fluid className="pb-3">
            <Row>
                <Col className="mt-4">
                    <PureBreadcrumbs />
                </Col>
            </Row>

            {/* <Row className="mt-3">
                <Col>
                    <Card className="headerCard">
                        <Row>
                            <Col xs={12} md={3} >
                                <Card className="innercard">
                                    <div style={{ color: "#FFB400" }}>
                                        <span>
                                            Impressions
                                        </span>
                                        <span>
                                            <h2>10k</h2>
                                        </span>
                                    </div>

                                </Card>
                            </Col>
                            <Col xs={12} md={3}>
                                <Card className="innercard">
                                    <div style={{ color: "#0090FF" }}>
                                        <span>
                                            Clicks
                                        </span>
                                        <span>
                                            <h2>22k</h2>
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={3}>
                                <Card className="innercard">
                                    <div style={{ color: "#80FE13" }}>
                                        <span>
                                            Sends
                                        </span>
                                        <span>
                                            <h2>25k</h2>
                                        </span>
                                    </div>
                                </Card>

                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Card className="innercard">
                                    <div style={{ color: "#FF00EE" }}>
                                        <span>
                                            Delivered
                                        </span>
                                        <span>
                                            <h2>09k</h2>
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */}

            {/* <Row className="mt-3">
                <Col>
                    <Card className="graphcard">
                        <div>
                            <h3 className="p-3">Impression
                            </h3>
                            <Bar options={options} data={data} />
                        </div>
                    </Card>
                </Col>
            </Row> */}

            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pl-4 pr-4">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b>  <div className="text-left">Campaign Graph</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            <CampaignChart data={graphData} loading={props.loading} />
                        </div>
                    </Card>
                </div>
            </div>

            <div className="col-lg-12 col-sm-12 mt-3">
                <Card className="graphcard">
                    <Card.Header>
                        <Row>
                            <Col xs={12} md={8}>
                                <strong><div className="text-left">Email Campaign</div></strong>
                            </Col>
                        </Row>
                    </Card.Header>
                    <div>
                        <div className="App">
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                    <h3 className="mr-3">Loading</h3>
                                    <Loader
                                        type="ThreeDots"
                                        color="#232959"
                                        height="40"
                                        width="40"
                                    />
                                </div>
                            ) : emailData && emailData.length > 0 ? (
                                <Table data={emailData} columns={emailColumns} />
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <h3>No data found</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
            </div>

            <div className="col-lg-12 col-sm-12 mt-3">
                <Card className="graphcard">
                    <Card.Header>
                        <Row>
                            <Col xs={12} md={8}>
                                <strong><div className="text-left">SMS Campaign</div></strong>
                            </Col>
                        </Row>
                    </Card.Header>
                    <div>
                        <div className="App">
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                    <h3 className="mr-3">Loading</h3>
                                    <Loader
                                        type="ThreeDots"
                                        color="#232959"
                                        height="40"
                                        width="40"
                                    />
                                </div>
                            ) : smsData && smsData.length > 0 ? (
                                <Table data={smsData} columns={smsColumns} />
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <h3>No data found</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
            </div>

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        fetchAllEmailRes: state.lead.fetchAllEmail,
        fetchAllSmsRes: state.lead.fetchAllSms,
        fetchCampaignGraphRes: state.lead.fetchCampaignGraph,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllEmail: () => dispatch(fetchAllEmailAction()),
        fetchAllSms: () => dispatch(fetchAllSmsAction()),
        fetchCampaignGraphData: () => dispatch(fetchCampaignGraphAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Campaign)

