import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import { fetchAllProjectApi, getNewTicketListAction, updateProjectStatusAction } from "../../../../store/actions/projectAction";
import moment from "moment";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";

function ProjectList(props) {
    useEffect(() => {
        props.fetchAllProject()
    }, [])

    const data = props.list && props.list.data
    // console.log("data", data)

    const handleStatusChange = (id, e) => {
        const status = e.target.value;
        if (status) props.updateProjectStatus(id, status);
    };

    if (props.updateProjectStatusRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'contact_data.firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'contact_data.lastname'
            },
            {
                Header: 'Email',
                accessor: 'contact_data.email'
            },
            {
                Header: 'Mobile',
                accessor: 'contact_data.mobile'
            },
            {
                Header: 'Address',
                accessor: 'contact_data.address'
            },
            {
                Header: 'Creation Date',
                id: 'createat',
                accessor: d => {
                    return moment(d.createat).local().format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Last Updated',
                id: 'updateat',
                accessor: d => {
                    return moment(d.updateat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                id: 'id1',
                accessor: "id",
                Cell: ({ row }) => {
                    const rowData = row.original;
                    const status = rowData.status;
                    const id = rowData.id;
                    // console.log(row)
                    return (
                        <div>
                            <select className="" onChange={(e) => handleStatusChange(id, e)} >
                                <option value={status} hidden>{status}</option>
                                <option value="" disabled>Select Project Status</option>
                                <option value="New">New</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Lost">Lost</option>
                                <option value="Completed">Completed</option>
                            </select>
                        </div >
                    )
                }
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_project/viewSingleProject/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updateProject/${id.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                        </div >
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid >

            <div className="App">
                {props.loading ? (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                        <h3>Loading</h3>
                        <Loader
                            type="ThreeDots"
                            color="#232959"
                            height="40"
                            width="40"
                        />
                    </div>
                ) : data && data.length > 0 ? (
                    <Table data={data} columns={columns} />
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                        <h3>No data found</h3>
                    </div>
                )}
            </div>
            <br />
            <br />

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        list: state.project.fetchAllProject,
        updateProjectStatusRes: state.project.updateProjectStatus
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllProject: () => dispatch(fetchAllProjectApi()),
        updateProjectStatus: (id, status) => dispatch(updateProjectStatusAction(id, status)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);