import React, { useMemo, useEffect, useState } from "react";
import { connect, ConnectedProps, RootStateOrAny } from "react-redux";
import { Card, Col, Container, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import PureBreadcrumbs from "../../Breadcrums";
import Table from "../../reacttable/table";
import { deleteSinglePanelPricingAction, fetchAllPanelPricingAction } from "../../../../store/actions/modulePricingAction";
import { MdDelete } from "react-icons/md";

type PricingData = {
    id: string;
    manufacturer: string;
    technology: string;
    role: string;
    operator: string;
    createdate: string;
};

type ViewPanelPricingProps = ConnectedProps<typeof connector>;

const ViewPanelPricing: React.FC<ViewPanelPricingProps> = (props) => {
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    useEffect(() => {
        props.fetchAllPrice();
    }, []);

    const data = props.list?.data;
    // console.log("data", data);

    const deleteSinglePanel = (id: string): void => {
        // console.log("id", id);
        props.deletePanelApi(id)
    }
    useEffect(() => {
        if (props.deleteSinglePanelRes && props.deleteSinglePanelRes.statuscode === 200) {
            setDeleteSuccess(true);
        }
    }, [props.deleteSinglePanelRes]);

    useEffect(() => {
        if (deleteSuccess) {
            alert("Panel deleted successfully");
            setDeleteSuccess(false);
            window.location.reload();
        }
    }, [deleteSuccess]);

    const columns = useMemo(
        () => [
            {
                Header: 'Manufacture Name',
                accessor: 'manufacturer'
            },
            {
                Header: 'Technology',
                accessor: 'technology'
            },
            {
                Header: 'Role',
                accessor: 'role'
            },
            {
                Header: 'Organisation',
                accessor: 'operator'
            },
            {
                Header: 'Creation Date',
                id: 'createdate',
                accessor: (d: PricingData) => {
                    return moment(d.createdate)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: ({ value }: { value: string }) => {
                    // console.log(value)
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_panel_pricing/viewSinglePanelPricing/${value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updatePanelPricing/${value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <MdDelete style={{ color: "red" }} onClick={() => deleteSinglePanel(value)} />
                            </span>
                        </div >
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">Panel Pricing List</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    Loading
                                    <h3 className="loader">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                                            <Loader
                                                type="ThreeDots"
                                                color="#232959"
                                                height={40}
                                                width={40}
                                            />
                                        </div>
                                    </h3>
                                </div>
                            ) : data && data.length > 0 ? (
                                <div>
                                    <Table data={data} columns={columns} />
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>

        </Container>
    );
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        loading: state.price.isLoading,
        list: state.price.fetchAllPanelPricing,
        deleteSinglePanelRes: state.price.deletePanelPricing,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllPrice: () => dispatch(fetchAllPanelPricingAction()),
        deletePanelApi: (id: string) => dispatch(deleteSinglePanelPricingAction(id)),
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ViewPanelPricing);
