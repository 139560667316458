const initState = {
    isLoading: false,
    createNewProject: '',
    fetchAllProject: '',
    fetchSingleProject: '',
    fetchSingleViewProject: '',
    updateProject: '',
    fetchCountryCode: '',
    updateProjectStatus: '',
    fetchProjectCount: '',
    fetchProjectStatusList: '',
    fetchRoofTypeList: '',
    fetchDataSourceList: '',
}

const projectReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PROJECT_LOADING':
            return { ...state, isLoading: true }
        case 'CREATE_NEW_PROJECT':
            return { ...state, createNewProject: action.payload, isLoading: true }
        case 'FETCH_ALL_PROJECTS':
            return { ...state, fetchAllProject: action.payload, isLoading: false }
        case 'FETCH_SINGLE_PROJECT':
            return { ...state, fetchSingleProject: action.payload, isLoading: true }
        case 'FETCH_SINGLE_VIEW_PROJECT':
            return { ...state, fetchSingleViewProject: action.payload, isLoading: false }
        case 'UPDATE_PROJECT_API':
            return { ...state, updateProject: action.payload, isLoading: true }
        case 'FETCH_COUNTRY_CODE_API':
            return { ...state, fetchCountryCode: action.payload, isLoading: false }
        case 'UPDATE_PROJECT_STATUS_API':
            return { ...state, updateProjectStatus: action.payload, isLoading: false }
        case 'FETCH_PROJECT_COUNT_API':
            return { ...state, fetchProjectCount: action.payload, isLoading: false }
        case 'FETCH_INSIGHT_PROJECT_STATUS_LIST_API':
            return { ...state, fetchProjectStatusList: action.payload, isLoading: false }
        case 'FETCH_ROOF_TYPE_LIST':
            return { ...state, fetchRoofTypeList: action.payload, isLoading: false }
        case 'FETCH_DATA_SOURCE_LIST':
            return { ...state, fetchDataSourceList: action.payload, isLoading: false }
        default:
            return state;
    }
}

export default projectReducer;