const initState = {
    isLoading: false,
    getAllContact: '',
    updateContact: '',
    getSingleContact: '',
    deleteSingleContact: '',
    fetchContactThroughEmail: '',
    validateEmail: '',
    createContact: '',
    validateContactEmail: '',
    downloadProposal: '',
    fetchProjectGraph: '',
    calculateProjectPrice: '',
    generate_proposal_url: '',
    triggerEmailforProposal: '',
}

const customerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CUSTOMER_LOADING':
            return { ...state, isLoading: true }
        case 'FETCH_ALL_CONTACT':
            return { ...state, getAllContact: action.payload, isLoading: false }
        case 'UPDATE_SINGLE_COTACT':
            return { ...state, updateContact: action.payload, isLoading: false }
        case 'GET_SINGLE_COTACT':
            return { ...state, getSingleContact: action.payload, isLoading: false }
        case 'DELETE_SINGLE_COTACT':
            return { ...state, deleteSingleContact: action.payload, isLoading: false }
        case 'FETCH_CONTACT_THROUGH_EMAIL':
            return { ...state, fetchContactThroughEmail: action.payload, isLoading: false }
        case 'VALIDATE_PROJECT_EMAIL':
            return { ...state, validateEmail: action.payload, isLoading: false }
        case 'CREATE_CONTACT_API':
            return { ...state, createContact: action.payload, isLoading: false }
        case 'VALIDATE_CONTACT_EMAIL_REDUCER':
            return { ...state, validateContactEmail: action.payload, isLoading: false }
        case 'DOWNLOAD_PROPOSAL_API':
            return { ...state, downloadProposal: action.payload, isLoading: false }
        case 'FETCH_PROJECT_GRAPH_API':
            return { ...state, fetchProjectGraph: action.payload, isLoading: false }
        case 'CALCULATE_PROJECT_SUMMARY_PRICE_API':
            return { ...state, calculateProjectPrice: action.payload, isLoading: false }
        case 'PROPOSAL_URL_GENERATE':
            return { ...state, generate_proposal_url: action.payload, isLoading: false }
        case 'SEND_PROPOSAL_EMAIL':
            return { ...state, triggerEmailforProposals: action.payload, isLoading: false }
        default:
            return state;
    }
}

export default customerReducer;