import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import Table from "../../reacttable/table";
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import { FaEye } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai"
import { getPortalUserListAction, deactivatePortalUserData, userApporvalBySuperAdmin } from "../../../../store/actions/accessAction";
import moment from "moment";
import Swal from 'sweetalert2'
import ApprovalCard from "../approvals/approvalCard";

function AccessList(props) {
    const { role } = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        props.getPortalUserList()
    }, [])

    const handleStatusChange = (row, e) => {
        const userid = row.userid;
        // console.log("userid: ", userid)
        const status = e.target.value;
        // console.log("Status: ", status)
        props.manageApproval(userid, status);
    };

    if (props.approval.statuscode === 200) {
        window.location.reload();
    }

    const statusColorMap = {
        Approved: 'Green',
        Rejected: 'Red',
        Pending: "Blue",
    }

    // const activateDeactivateUser = (userid, accountstatus) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You want to deactivate this acount?",
    //         // type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d33',
    //         cancelButtonColor: '#1e295d',
    //         confirmButtonText: 'Yes!'
    //     }).then((result) => {
    //         if (result.value) {
    //             props.activatedeactivateUser(userid, accountstatus);
    //             window.location.reload();
    //         }
    //     });
    // }

    const data = props.list && props.list.data
    // console.log("data", data);

    const approvedCount = data && data.filter((item) => item.status === 'Approved').length
    const rejectedCount = data && data.filter((item) => item.status === 'Rejected').length


    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'first_name'
            },
            {
                Header: 'Last Name',
                accessor: 'last_name'
            },
            {
                Header: 'User ID',
                id: 'useridd',
                accessor: 'userid'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Role',
                accessor: 'role'
            }, {
                Header: 'Organization',
                accessor: 'organisation'
            },
            {
                Header: 'Creation Date',
                id: 'createdat',
                accessor: d => {
                    return moment(d.createdate)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Last Updated',
                id: 'updatedat',
                accessor: d => {
                    return moment(d.last_updated)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Status',
                accessor: "status",
                Cell: (props) => {
                    // console.log("props", props);
                    return (
                        <div style={{ color: statusColorMap[props.value], fontWeight: "bold" }}>{props.value}</div>
                    );
                }
            },
            {
                Header: 'Approval',
                id: 'userid',
                accessor: (userid) => {
                    const item = userid
                    // console.log(item)
                    return (
                        <div>
                            <select className="" onChange={(e) => handleStatusChange(userid, e)} >
                                <option value={item.status} hidden>{item.status}</option>
                                <option value={"Approved"}>Approved</option>
                                <option value={"Rejected"}>Rejected</option>
                            </select>
                        </div>
                    )
                }
            },
            {
                Header: 'Actions',
                id: "userid2",
                accessor: 'userid',
                Cell: (userid) => {
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/view_access/viewportaluser/${userid.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a></span>
                            &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;
                            <span>
                                <a href={`/dashboard/view_access/editportaluser/${userid.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <AiFillEdit />
                                </a></span>
                            {/* <span>
                                {userid ? <a href="#" rel="tooltip" title="Deactivate" id="username" className="table-action slash" onClick={() => activateDeactivateUser(userid.value, false)} >
                                    <i className="fas fa-user-check"></i></a> : <a href="#" rel="tooltip" title="Activate" id="username" className="table-action active" onClick={() => activateDeactivateUser(userid.value, true)}>
                                    <i className="fas fa-user-slash"></i></a>}
                            </span> */}
                        </div>

                    );
                },
            }
        ],
        []
    );

    return (
        <Container fluid className="pb-4">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-4 col-sm-12">
                    <Card className='statuscard total-card-background'>
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "center" }}>
                            <span className="customercardtext"> Total User </span>
                        </div>
                        <span className="customercount" style={{ textAlign: "center" }}>{props.list && props.list.data ? props.list.data.length : 0}
                        </span>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className='statuscard total-active-card-background'>
                        <div className="diagonal-line"></div>

                        <div style={{ textAlign: "center" }}>
                            <span className="customercardtext">Active User </span>
                        </div>
                        <span className="customercount" style={{ textAlign: "center" }}> {approvedCount ? approvedCount : 0}
                        </span>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className='statuscard total-inactive-card-background'>
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "center" }}>
                            <span className="customercardtext">Inactive User</span>
                        </div>
                        <span className="customercount" style={{ textAlign: "center" }}>
                            {rejectedCount ? rejectedCount : 0}
                        </span>
                    </Card>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b>  <div className="text-left">User List</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                    <h3>Loading</h3>
                                    <Loader
                                        type="MutatingDots"
                                        color="#232959"
                                        height={100}
                                        width={100}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    />
                                </div>
                            ) : data && data.length > 0 ? (
                                <div className="App">
                                    <Table data={data} columns={columns} />
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>


        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.access.isLoading,
        list: state.access.portalUserList,
        deactive: state.access.deactivatePortalUserData,
        approval: state.access.userApprovalBySuperAdmin,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getPortalUserList: () => dispatch(getPortalUserListAction()),
        activatedeactivateUser: (userid, accountstatus) => dispatch(deactivatePortalUserData(userid, accountstatus)),
        manageApproval: (userid, status) => dispatch(userApporvalBySuperAdmin(userid, status)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccessList);


