// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-privilage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.my_Privilagecontainer input[type="checkbox"] {
    /* width: 180%; */
    height: 18px;
}

.my_SubPrivilagecontainer input[type="checkbox"] {
    /* width: 20px; */
    height: 18px;
}

.my_region_container input[type="checkbox"] {
    margin-top: 1rem;
    width: 22px;
    height: 18px;
}

#subcontainer {
    display: flex;
    justify-content: start;
    border-bottom: 2px solid gray;
    padding-bottom: 9px;
    padding-top: 12px;
}

.subprivilage_column {
    overflow-x: scroll;
    scrollbar-width: thin;
    /* -ms-overflow-style: none; */
}

.password_field {
    background-color: #d0cbcb;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/admin/accessmanagement/accessManagement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".sub-privilage-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.my_Privilagecontainer input[type=\"checkbox\"] {\n    /* width: 180%; */\n    height: 18px;\n}\n\n.my_SubPrivilagecontainer input[type=\"checkbox\"] {\n    /* width: 20px; */\n    height: 18px;\n}\n\n.my_region_container input[type=\"checkbox\"] {\n    margin-top: 1rem;\n    width: 22px;\n    height: 18px;\n}\n\n#subcontainer {\n    display: flex;\n    justify-content: start;\n    border-bottom: 2px solid gray;\n    padding-bottom: 9px;\n    padding-top: 12px;\n}\n\n.subprivilage_column {\n    overflow-x: scroll;\n    scrollbar-width: thin;\n    /* -ms-overflow-style: none; */\n}\n\n.password_field {\n    background-color: #d0cbcb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
