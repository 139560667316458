const initState = {
    isLoading: false,
    saveAccessControl: '',
    portalUserList: '',
    viewPortalUserData: '',
    editPortalUserData: '',
    deactivatePortalUserData: '',
    userApprovalBySuperAdmin: '',
    changePassword: '',
}

const accessReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ACCESS_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_ACCESS_CONTROL':
            return { ...state, saveAccessControl: action.payload, isLoading: false }
        case 'PORTAL_USER_LIST':
            return { ...state, portalUserList: action.payload, isLoading: false }
        case 'PORTAL_USER_VIEW':
            return { ...state, viewPortalUserData: action.payload, isLoading: false }
        case 'PORTAL_USER_EDIT':
            return { ...state, editPortalUserData: action.payload, isLoading: false }
        case 'PORTAL_USER_DEACTIVATE':
            return { ...state, deactivatePortalUserData: action.payload, isLoading: false }
        case 'USER_APPROVAL_BYSUPERADMIN':
            return { ...state, userApprovalBySuperAdmin: action.payload, isLoading: false }
        case 'CHANGE_USER_PASSWORD_API':
            return { ...state, changePassword: action.payload, isLoading: false };
        default:
            return state;
    }
}

export default accessReducer;