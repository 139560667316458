// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usr_img {
    width: 10rem;
    margin-left: 25%;
}

.usr_card {
    width: 22rem;
    border-radius: 10px;
    border: none;
    /* background: #F1F1F1 0% 0% no-repeat padding-box; */
}

@media (max-width: 576px) {
    .usr_card {
        margin-top: 2rem;
        width: 14rem;
        border-radius: 10px;
        background: #F1F1F1 0% 0% no-repeat padding-box;
    }

    .usr_img {
        width: 8rem;
        margin-left: 20%;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/setting/settings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,qDAAqD;AACzD;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;QACZ,mBAAmB;QACnB,+CAA+C;IACnD;;IAEA;QACI,WAAW;QACX,gBAAgB;IACpB;;AAEJ","sourcesContent":[".usr_img {\n    width: 10rem;\n    margin-left: 25%;\n}\n\n.usr_card {\n    width: 22rem;\n    border-radius: 10px;\n    border: none;\n    /* background: #F1F1F1 0% 0% no-repeat padding-box; */\n}\n\n@media (max-width: 576px) {\n    .usr_card {\n        margin-top: 2rem;\n        width: 14rem;\n        border-radius: 10px;\n        background: #F1F1F1 0% 0% no-repeat padding-box;\n    }\n\n    .usr_img {\n        width: 8rem;\n        margin-left: 20%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
