import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums.js";
import "./finance.css";
// import { HandleKeyPress } from "../reusableComponent/handleKeyPress";
import ReactSelect from "react-select";
import { fetchAllLeadAction } from "../../../store/actions/leadAction.js";
import { HandleKeyPress } from "../reusableComponent/handleKeyPress.js";
import { fetchFinanceNoOfMonthsAction, fetchProjectListWithEmailAction, saveFinanceAction } from "../../../store/actions/financeAction.js";
import { fetchAllProjectApi } from "../../../store/actions/projectAction.js";

interface FormValues {
    emailid: string;
    project_title: string;
    project_amount: number;
    payment_type: string;
    final_project_amount: number;
    no_of_months: number;
    is_interest_free: string;
    interest_rate: number;
    payment_due_date: string;
}

type MonthData = {
    key: number;
    value: number;
};

const validationSchema = Yup.object().shape({
    emailid: Yup.string().required('Email Id is required').typeError('Email Id is required'),
    project_title: Yup.string().required('Project title is required').typeError('Project title is required'),
    payment_type: Yup.string().required('Payment type is required').typeError("Payment type is required"),
    no_of_months: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().nullable(), // no_of_months can be null if 'One-Time'
            otherwise: Yup.number().required('No. of months is required').typeError("No. of months is required"),
        }),
    project_amount: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().nullable(), // project_amount can be null if 'One-Time'
            otherwise: Yup.number().required('Project amount is required').typeError('Project amount is required'),
        }),
    final_project_amount: Yup.number()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.number().required('Final project amount is required').typeError('Final project amount is required'),
            otherwise: Yup.number().nullable(),
        }),
    is_interest_free: Yup.string()
        .when('payment_type', {
            is: 'One-Time',
            then: Yup.string().nullable(), // is_interest_free can be null if 'One-Time'
            otherwise: Yup.string().required('Is interest free is required').typeError('Is interest free is required'),
        }),
    interest_rate: Yup.number()
        // .transform((value, originalValue) => originalValue === '' ? null : value) // Transform empty string to null for number type
        // .nullable()
        .when(['payment_type', 'is_interest_free'], {
            is: (payment_type, is_interest_free) => payment_type === 'One-Time' || is_interest_free === 'true',
            then: Yup.number().nullable(), // interest_rate can be null if payment_type is 'One-Time' or is_interest_free is 'true'
            otherwise: Yup.number().required('Interest rate is required').typeError('Interest rate is required'),
        }),
    payment_due_date: Yup.string().required('Payment due date is required').matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Payment due date must be a valid date in the format YYYY-MM-DD'),
});

const CreateFinance: React.FC<PropsFromRedux> = (props) => {
    const today = new Date().toISOString().split('T')[0];
    const [alertShown, setAlertShown] = useState(false);

    useEffect(() => {
        // props.fetchAllLeadApi();
        props.fetchNumberOfMonthsApi();
        props.fetchAllProject()
    }, []);

    // console.log("props", props);
    const { register, handleSubmit, reset, setValue, resetField, control, trigger, getValues, watch, formState: { errors, isValid } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all",
    });
    const financeData = watch();
    const isInterestFree = financeData?.is_interest_free;
    const payment_type = financeData?.payment_type;
    const interest_rate = financeData?.interest_rate;
    const project_amount = financeData?.project_amount;
    // console.log("finance data", financeData)

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        // console.log("data", data)
        props.saveFinanceApi(data);
    };

    const data = props.list && props.list.data
    // console.log("data", data);

    const options = data && data.map(project => ({
        value: project.user_email,
        label: project.user_email
    }));
    const selectedEmail = financeData.emailid;
    const selectedEmailOption = options && options.find(option => option.value === selectedEmail);

    const handleEmailChange = (obj: any) => {
        resetField('project_title')
        selectedProjectTitleOption = {};
        selectedProjectTitle = '';
        const value = obj.value;
        setValue("emailid", value);
        if (value) {
            props.fetchProjectListApi(value);
        }
    }

    const projectTitleApi = props.fetchProjectListRes && props.fetchProjectListRes.data
    // console.log("data", data);

    const projectTitleOptions = projectTitleApi && projectTitleApi.map(item => ({
        value: item.project_title,
        label: item.project_title
    }));
    let selectedProjectTitle = financeData.project_title;
    let selectedProjectTitleOption = projectTitleOptions && projectTitleOptions.find(option => option.value === selectedProjectTitle);
    // console.log("selectedProjectTitle", selectedProjectTitle)
    // console.log("selectedProjectTitleOption", selectedProjectTitleOption)

    const handleProjectTitleChange = (obj) => {
        const value = obj.value;
        // console.log("Project title: ", value);
        setValue("project_title", value);
    }

    const handlePaymentTypeChange = (e: any) => {
        const value = e.target.value;
        // console.log("payement type", value)
        setValue("payment_type", value);
        resetField('no_of_months');
        resetField('is_interest_free');
        resetField('interest_rate');
        resetField('project_amount');
        resetField('final_project_amount');
    };
    // console.log("errors", errors)

    // This component is preventing input field from entering 0 ,-ve and non-numeric values & characters.

    const handlePressKeyForInterestRate = (e: any) => {
        const value = e.target.value;
        const key = e.key;

        // Allow navigation keys (backspace, delete, arrow keys)
        const navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (navigationKeys.includes(key)) {
            return;
        }

        // Allow only one decimal point
        if (key === '-' && value.includes('')) {
            e.preventDefault();
            return;
        }
        // Allow only one decimal point
        if (key === '.' && value.includes('.')) {
            e.preventDefault();
            return;
        }

        // Prevent entering '.' as the first character
        if (key === '.' && value === '') {
            e.preventDefault();
            return;
        }

        // Prevent entering non-numeric characters
        if (!/^\d$/.test(key) && key !== '-' && key !== '.') {
            e.preventDefault();
            return;
        }

    };

    // Logic for calculating final amount when payment type is Recurring
    useEffect(() => {
        if (payment_type !== 'One-Time' && project_amount) {
            if (interest_rate) {
                // Calculate the interest
                const interest = Number(project_amount) * (Number(interest_rate) / 100);
                // Final amount is project amount + interest
                const final = Number(project_amount) + Number(interest);
                // console.log("final", final);
                setValue('final_project_amount', final);
            } else {
                // If no interest rate, reset final amount to project amount
                setValue('final_project_amount', Number(project_amount));
            }
        }

    }, [project_amount, interest_rate, payment_type, setValue]);


    useEffect(() => {
        if (props.saveFinanceRes && props.saveFinanceRes.statuscode === 200) {
            // setAlertShown(true);
            alert("Finance has been created successfully");
            // setAlertShown(false);
            window.location.reload();
        }
        if (props.saveFinanceRes && props.saveFinanceRes.statuscode === 405) {
            // setAlertShown(true);
            alert(props.saveFinanceRes.error);
            // setAlertShown(false);
            // window.location.reload();
        }
    }, [props.saveFinanceRes]);

    return (
        <Container fluid className="pb-4">
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Create Finance</h4>
                </Col>
            </Row>
            <form onSubmit={(e) => e.preventDefault} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>Create Finance</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="emailid">Email <span className="mandatory-field">*</span></label>
                                                {/* React Select with React Hook Form */}
                                                <Controller
                                                    name="emailid"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ReactSelect
                                                            {...field}
                                                            options={options ? options : []}
                                                            className={` ${errors.emailid ? 'is-invalid' : ''}`}
                                                            placeholder="Email"
                                                            value={selectedEmailOption}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                                }),
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleEmailChange(selectedOption);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.emailid && <span className="invalid-feedback">{errors.emailid.message}</span>}
                                            </Col>
                                            <Col md={6} >
                                                <label htmlFor="project_title">Project Title <span className="mandatory-field">*</span></label>
                                                <Controller
                                                    name="project_title"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ReactSelect
                                                            {...field}
                                                            options={projectTitleOptions ? projectTitleOptions : []}
                                                            className={` ${errors.project_title ? 'is-invalid' : ''}`}
                                                            placeholder="Project Title"
                                                            value={selectedProjectTitleOption || null}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                                }),
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleProjectTitleChange(selectedOption);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                < div className="invalid-feedback" > {errors.project_title?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="floatingSelectGridPaymentType">{("Payment Type")} <span className='mandatory-field'>*</span></label>
                                                <div className="">
                                                    <select className={`form-select form-control ${errors.payment_type ? 'is-invalid' : 'input-normal'}`} name="payment_type" id="floatingSelectGridPaymentType" onChange={(e) => handlePaymentTypeChange(e)} >
                                                        <option value="">{("Select Payment Type")}</option>
                                                        <option value="One-Time">{("One-Time")}</option>
                                                        <option value="Recurring">{("Recurring")}</option>
                                                    </select>
                                                    <div className="invalid-feedback" > {errors.payment_type?.message} </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridPayment_due_date">{("Payment due date (dd/mm/yyyy)")} <span className='mandatory-field'>*</span></label>
                                                <input
                                                    type="date"
                                                    className={`form-control gray-border ${errors.payment_due_date ? 'is-invalid' : 'input-normal'}`}
                                                    id="floatingInputGridPayment_due_date"
                                                    placeholder={("Date")}
                                                    {...register('payment_due_date', {
                                                        required: 'Date is required',
                                                        validate: {
                                                            correctFormat: (value) => {
                                                                // This is a custom validator to check if the date format is correct.
                                                                const datePattern = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy format
                                                                return datePattern.test(value) || 'Invalid date format';
                                                            },
                                                            validDate: (value) => {
                                                                // Ensure the input is a valid date
                                                                return !isNaN(Date.parse(value)) || 'Invalid date';
                                                            },
                                                        },
                                                    })}
                                                    max={today}
                                                />
                                                < div className="invalid-feedback" > {errors.payment_due_date?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            {payment_type === 'Recurring' &&
                                                <Col md={12}>
                                                    <label htmlFor="project_amount">Project Amount <span className="mandatory-field">*</span></label>
                                                    <input
                                                        type="number"
                                                        className={`input_login ${errors.project_amount ? 'is-invalid input-with-error' : 'input-normal'}`}
                                                        id="project_amount"
                                                        placeholder="Project Amount"
                                                        onKeyDown={HandleKeyPress}
                                                        {...register("project_amount")}
                                                    />
                                                    <div className="invalid-feedback" > {errors.project_amount?.message} </div>
                                                </Col>
                                            }
                                        </Row>


                                        {payment_type === 'Recurring' &&
                                            <Row className="mt-3" >
                                                <Col md={isInterestFree === 'true' ? 6 : 4}>
                                                    <label htmlFor="floatingSelectGridPaymentType">{("No. of months")} <span className='mandatory-field'>*</span></label>
                                                    <div className="">
                                                        <select className={`form-select form-control ${errors.no_of_months ? 'is-invalid' : 'input-normal'}`} id="floatingSelectGridNoOfMonths" {...register('no_of_months')} >
                                                            <option value="">{("Select No.of Months")}</option>
                                                            {props.fetchNoOfMonthsListRes && props.fetchNoOfMonthsListRes.monthly_data?.map((item: MonthData) => (
                                                                <option key={item.key} value={item.key}>{item.value}</option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback" > {errors.no_of_months?.message} </div>
                                                    </div>
                                                </Col>
                                                <Col md={isInterestFree === 'true' ? 6 : 4}>
                                                    <label htmlFor="floatingSelectGridIs_interest_free">{("Is interest free ?")}</label>
                                                    <div className="">
                                                        <select className={`form-select form-control ${errors.is_interest_free ? 'is-invalid' : 'input-normal'}`} id="floatingSelectGridIs_interest_free"
                                                            {...register('is_interest_free', {
                                                                onChange: (e) => {
                                                                    const value = e.target.value;
                                                                    setValue('is_interest_free', value);
                                                                    resetField('interest_rate');
                                                                }
                                                            })} >
                                                            <option value="" hidden>{("Is interest free ?")}</option>
                                                            <option value='true'>{("Yes")}</option>
                                                            <option value='false'>{("No")}</option>
                                                        </select>
                                                        <div className="invalid-feedback" > {errors.is_interest_free?.message} </div>
                                                    </div>
                                                </Col>
                                                {isInterestFree !== 'true' ?
                                                    < Col md={4} >
                                                        <label htmlFor="interest_rate">Interest Rate in (%) <span className="mandatory-field">*</span></label>
                                                        <input
                                                            type="number"
                                                            className={`input_login ${errors.interest_rate ? 'is-invalid input-with-error' : 'input-normal'}`}
                                                            id="interest_rate"
                                                            placeholder="Interest Rate"
                                                            onKeyDown={handlePressKeyForInterestRate}
                                                            {...register("interest_rate")}
                                                        />
                                                        <div className="invalid-feedback" > {errors.interest_rate?.message} </div>
                                                    </Col>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </Row>
                                        }

                                        <Row className="mt-3" >
                                            <Col md={12} >
                                                <label htmlFor="interest_rate">Final Project Amount <span className="mandatory-field">*</span></label>
                                                < input
                                                    type="number"
                                                    className={`input_login ${errors.final_project_amount ? 'is-invalid input-with-error' : 'input-normal'}`}
                                                    id="final_project_amount"
                                                    placeholder="Final project amount"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("final_project_amount")}
                                                    disabled={payment_type !== 'One-Time'}
                                                />
                                                < div className="invalid-feedback" > {errors.final_project_amount?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center" >
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start" >
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    reset();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            < Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2' >
                                                {
                                                    props.loading ?
                                                        <button className="lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                                            {("Saving...")}
                                                        </button>
                                                        :
                                                        <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)} > {("CREATE FINANCE")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.finance.isLoading,
    // list: state.lead.fetchAllLead,
    list: state.project.fetchAllProject,
    fetchNoOfMonthsListRes: state.finance.fetchNoOfMonthsList,
    fetchProjectListRes: state.finance.fetchProjectList,
    saveFinanceRes: state.finance.saveFinance,

});

const mapDispatchToProps = (dispatch: any) => ({
    // fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
    fetchAllProject: () => dispatch(fetchAllProjectApi()),
    fetchNumberOfMonthsApi: () => dispatch(fetchFinanceNoOfMonthsAction()),
    fetchProjectListApi: (email: string) => dispatch(fetchProjectListWithEmailAction(email)),
    saveFinanceApi: (data: FormValues) => dispatch(saveFinanceAction(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateFinance);
