import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';
import "../home/homecard.css";


const StatusCard = ({ link, title, count, percentage, color }) => (
    <a href={link} className="status-card-link">
        <Card className='statuscard'>
            <div className="lead-card-header d-flex justify-content-between">
                <span className="leadCardText">{title}</span>
                <span className="count">{count || 0}</span>
            </div>
            <div className="card-content">
                <div className="progress-container">
                    <CircularProgressbar
                        value={percentage || 0}
                        text={`${percentage || 0}%`}
                        strokeWidth={10}
                        styles={buildStyles({
                            textSize: '20px',
                            pathColor: color,
                            textColor: '#000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#f8f9fa',
                        })}
                    />
                </div>
            </div>
        </Card>
    </a>
);

StatusCard.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default StatusCard;
