import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums"
import "./settings.css"

function Settings(props) {
    const { name, email, phone, city, state, country, postal_code, organisation } = JSON.parse(localStorage.getItem("user"));
    // console.log(JSON.parse(localStorage.getItem("user")))
    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="mt-2">
                        <PureBreadcrumbs />
                    </Col>
                </Row>
                <Card className="tariffcard mt-2">
                    <Col className="pl-0">
                        <Card className="usr_card d-flex flex-row align-items-center">
                            <img
                                className="usr_img m-2"
                                src={process.env.PUBLIC_URL + '/images/settings/profile.png'}
                                alt=""
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <Card.Body>
                                <Card.Title>{name}</Card.Title>
                                <Card.Text>
                                    <div>
                                        <b>Profile Creation Date</b>: 05-May-2022
                                    </div>
                                    <div>
                                        <b>Organisation</b>: {organisation}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Row>
                        <Col className="mt-2">
                            <Card.Title><span className="">Account Settings</span></Card.Title>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col lg={6} xs={12} md={4}>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridName">Firstname</label>

                                <input className="input" type="text" name="firstName" id="floatingInputGridName" placeholder="Firstname" defaultValue={name} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridEmail">Email</label>

                                <input className="input" type="text" name="email" id="floatingInputGridEmail" placeholder="Email" defaultValue={email} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridCity">City</label>

                                <input className="input" type="text" name="city" id="floatingInputGridCity" placeholder="City" defaultValue={city} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridCountry">Country</label>

                                <input className="input" type="text" name="country" id="floatingInputGridCountry" placeholder="Country" defaultValue={country} disabled />
                            </div>
                        </Col>
                        <Col lg={6} xs={12} md={4}>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridName">Lastname</label>

                                <input className="input" type="text" name="firstName" id="floatingInputGridName" placeholder="Lastname" defaultValue={name} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridPhone">Phone Number</label>

                                <input className="input" type="text" name="phone" id="floatingInputGridPhone" placeholder="Phone Number" defaultValue={phone} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridState">State</label>

                                <input className="input" type="text" name="state" id="floatingInputGridState" placeholder="State" defaultValue={state} disabled />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="floatingInputGridName">Zipcode</label>

                                <input className="input" type="text" name="postal_code" id="floatingInputGridPostal_code" placeholder="Zipcode" defaultValue={postal_code} disabled />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>

    )
}

export default Settings