import React, { useEffect } from "react"
import { connect } from "react-redux";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums"
import "../reacttable/reactTable.css"
import "./customerCard.css"
import ConatctList from "./contact/conatctList";
import { getAllContactAction } from "../../../store/actions/customerAction";

function Customer(props) {
  useEffect(() => {
    props.getAllContactData();
  }, [])

  const data = props.list && props.list.data
  // console.log("data", data);

  return (
    <Container fluid>
      <div className="row">
        <div className="col-lg-6 col-sm-12 mt-4 mb-3">
          <PureBreadcrumbs />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-4 col-sm-12">
          <Card className='statuscard total-card-background'>
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "center" }}>
              <span className="customercardtext"> Total Customer </span>
            </div>
            <span className="customercount" style={{ textAlign: "center" }}>
              {data ? data.length : 0}
            </span>
          </Card>
        </div>
        <div className="col-lg-4 col-sm-12">
          <Card className='statuscard total-active-card-background'>
            <div className="diagonal-line"></div>

            <div style={{ textAlign: "center" }}>
              <span className="customercardtext">Active Customer </span>
            </div>
            <span className="customercount" style={{ textAlign: "center" }}>0
            </span>
          </Card>
        </div>
        <div className="col-lg-4 col-sm-12">
          <Card className='statuscard total-inactive-card-background'>
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "center" }}>
              <span className="customercardtext">Inactive Customer</span>
            </div>
            <span className="customercount" style={{ textAlign: "center" }}>0
            </span>
          </Card>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 col-sm-12 pad_t1">
          <Card className="graphcard">
            <Card.Header>
              <Row>
                <Col xs={12} md={8}>
                  <b>  <div className="text-left">Customer List</div></b>
                </Col>
              </Row>
            </Card.Header>
            <div>
              <ConatctList />
            </div>
          </Card>
        </div>
      </div>
    </Container>
  )

}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    list: state.customer.getAllContact,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAllContactData: () => dispatch(getAllContactAction()),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Customer);


