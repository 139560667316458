import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { createPanelPricingAction } from "../../../../store/actions/modulePricingAction.js";

interface FormValues {
    manufacture: string;
    technology: string;
    ratedOutputPower: string;
    width: string;
    height: string;
    thickness: string;
    weight: string;
    Vmp: string;
    Voc: string;
    Imp: string;
    Isc: string;
    pricePerModule: string;
    panelAdditionalCharge: string;
}

const validationSchema = Yup.object().shape({
    manufacture: Yup.string().required('Manufacture is required'),
    technology: Yup.string().required('Technology is required'),
    ratedOutputPower: Yup.string().required('Rated Output Power is required').typeError("Rated Output Power is required"),
    width: Yup.string().required('Width is required').typeError("Width is required"),
    height: Yup.string().required('Height is required').typeError("Height is required"),
    thickness: Yup.string().required('Thickness is required').typeError("Thickness is required"),
    weight: Yup.string().required('Weight is required').typeError("Weight is required"),
    Vmp: Yup.string().required('Vmp is required').typeError("Vmp is required"),
    Voc: Yup.string().required('Voc is required').typeError("Voc is required"),
    Imp: Yup.string().required('Imp is required').typeError("Imp is required"),
    Isc: Yup.string().required('Isc is required').typeError("Isc is required"),
    pricePerModule: Yup.string().required('Price/Moduel is required').typeError("Price/Moduel is required"),
    panelAdditionalCharge: Yup.string().required('Panel additional charge is required').typeError("Panel additional charge is required"),
});

const CreatePanelPricing: React.FC<PropsFromRedux> = (props) => {
    // console.log("props", props);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        props.savePricing(data);
        // console.log("data", data)
    };

    useEffect(() => {
        if (props.savePricingRes && props.savePricingRes.statuscode === 200) {
            alert("Panel Pricing has been created successfully");
            window.location.reload();
        }
        if (props.savePricingRes && props.savePricingRes.statuscode === 405) {
            alert(props.savePricingRes.error);
            window.location.reload();
        }
    }, [props.savePricingRes]);

    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Create Panel Pricing </h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>Create Pricing </span>
                                        </Card.Title>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="title" >Manufacture</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.manufacture ? 'is-invalid' : ''}`
                                                    }
                                                    id="manufacture"
                                                    placeholder="Manufacture"
                                                    {...register("manufacture")}
                                                />
                                                < div className="invalid-feedback"> {errors.manufacture?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="technology">Technology</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.technology ? 'is-invalid' : ''}`}
                                                    id="technology"
                                                    placeholder="Technology"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("technology")}
                                                />
                                                < div className="invalid-feedback" > {errors.technology?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                                <label htmlFor="ratedOutputPower" >Rated Output Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.ratedOutputPower ? 'is-invalid' : ''}`}
                                                    id="ratedOutputPower"
                                                    placeholder="Rated Output Power"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("ratedOutputPower")}
                                                />
                                                < div className="invalid-feedback" > {errors.ratedOutputPower?.message} </div>
                                            </Col>
                                            <Col md={4}>
                                                <label htmlFor="height" >Height</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.height ? 'is-invalid' : ''}`}
                                                    id="height"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Height"
                                                    {...register("height")}
                                                />
                                                < div className="invalid-feedback" > {errors.height?.message} </div>
                                            </Col>
                                            < Col md={4} >
                                                <label htmlFor="price_per_battery" >Width</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.width ? 'is-invalid' : ''}`}
                                                    id="width"
                                                    placeholder="Width"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("width")}
                                                />
                                                < div className="invalid-feedback" > {errors.width?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            < Col md={6} >
                                                <label htmlFor="thickness" >Thickness</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.thickness ? 'is-invalid' : ''}`}
                                                    id="thickness"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Thickness"
                                                    {...register("thickness")}
                                                />
                                                < div className="invalid-feedback" >{errors.thickness?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="weight">Weight</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.weight ? 'is-invalid' : ''}`}
                                                    id="weight"
                                                    placeholder="Weight"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("weight")}
                                                />
                                                < div className="invalid-feedback" > {errors.weight?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6} >
                                                <label htmlFor="Vmp">Voltage at Maximum Power (Vmp)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.Vmp ? 'is-invalid' : ''}`}
                                                    id="Vmp"
                                                    placeholder="Voltage at Maximum Power (Vmp)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Vmp")}
                                                />
                                                < div className="invalid-feedback" > {errors.Vmp?.message} </div>
                                            </Col>
                                            <Col md={6} >
                                                <label htmlFor="Voc">Open-Circuit Voltage (Voc)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.Voc ? 'is-invalid' : ''}`}
                                                    id="Voc"
                                                    placeholder="Open-Circuit Voltage (Voc)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Voc")}
                                                />
                                                < div className="invalid-feedback" > {errors.Voc?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="Imp">Current at Maximum Power (Imp)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.Imp ? 'is-invalid' : ''}`}
                                                    id="Imp"
                                                    placeholder="Current at Maximum Power (Imp)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Imp")}
                                                />
                                                < div className="invalid-feedback" > {errors.Imp?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="Isc" >Short Circuit Current (Isc)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.Isc ? 'is-invalid' : ''}`}
                                                    id="Isc"
                                                    placeholder="Short Circuit Current (Isc)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Isc")}
                                                />
                                                < div className="invalid-feedback" > {errors.Isc?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="pricePerModule">Price/Module</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.pricePerModule ? 'is-invalid' : ''}`}
                                                    id="pricePerModule"
                                                    placeholder="Price/Module"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("pricePerModule")}
                                                />
                                                < div className="invalid-feedback" > {errors.pricePerModule?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="panelAdditionalCharge">Panel Additional Charge</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.panelAdditionalCharge ? 'is-invalid' : ''}`}
                                                    id="panelAdditionalCharge"
                                                    placeholder="Panel Additional Charge"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("panelAdditionalCharge")}
                                                />
                                                < div className="invalid-feedback" > {errors.panelAdditionalCharge?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center" >
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start" >
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    reset();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            < Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2' >
                                                {
                                                    props.loading ?
                                                        <button className="lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                                            {("Saving...")}
                                                        </button>
                                                        :
                                                        <button className="lgn-btn" type="submit" > {("CREATE PANEL PRICING")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    savePricingRes: state.price.createPanelPricing,
});

const mapDispatchToProps = (dispatch: any) => ({
    savePricing: (data: FormValues) => dispatch(createPanelPricingAction(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreatePanelPricing);
