// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customercard {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    border-color: #FFFFFF;
}

#a{
    text-decoration: none;
}

.total-card-background {
    background: linear-gradient(45deg, var(--total_total_card_first_background_color), var(--total_total_card_second_background_color));
}

.total-active-card-background {
    background: linear-gradient(45deg, var(--total_active_card_first_background_color), var(--total_active_card_second_background_color));
}

.total-inactive-card-background {
    background: linear-gradient(45deg, var(--total_inactive_card_first_background_color), var(--total_inactive_card_second_background_color));
}
.diagonal-line {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(69deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
    background-size: 10px 10px;
    top: 2px;
    left: 2px;  
}

.customercardtext{
    font-weight: 700;
    font-size: 20px;
    color: var(--text-primary);
}
.customercount {
    font-weight: 400;
    font-size: 26px;
    color: var(--text-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/customer/customerCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+CAA+C;IAC/C,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mIAAmI;AACvI;;AAEA;IACI,qIAAqI;AACzI;;AAEA;IACI,yIAAyI;AAC7I;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kMAAkM;IAClM,0BAA0B;IAC1B,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".customercard {\n    width: 100%;\n    background: #FFFFFF 0% 0% no-repeat padding-box;\n    border-radius: 10px;\n    padding: 10px;\n    border-color: #FFFFFF;\n}\n\n#a{\n    text-decoration: none;\n}\n\n.total-card-background {\n    background: linear-gradient(45deg, var(--total_total_card_first_background_color), var(--total_total_card_second_background_color));\n}\n\n.total-active-card-background {\n    background: linear-gradient(45deg, var(--total_active_card_first_background_color), var(--total_active_card_second_background_color));\n}\n\n.total-inactive-card-background {\n    background: linear-gradient(45deg, var(--total_inactive_card_first_background_color), var(--total_inactive_card_second_background_color));\n}\n.diagonal-line {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background-image: linear-gradient(69deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);\n    background-size: 10px 10px;\n    top: 2px;\n    left: 2px;  \n}\n\n.customercardtext{\n    font-weight: 700;\n    font-size: 20px;\n    color: var(--text-primary);\n}\n.customercount {\n    font-weight: 400;\n    font-size: 26px;\n    color: var(--text-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
