import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { fetchSingleBatteryPricingAction, updateBatteryPricingAction } from "../../../../store/actions/modulePricingAction.js";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min.js";

interface FormValues {
    manufacturer: string;
    sku: string;
    total_energy: string;
    max_continuous_power: string;
    efficiency: string;
    price_per_battery: string;
    battery_additional_charges: string;
}

interface SingleBatteryPricingData {
    manufacturer?: string;
    sku?: string;
    total_energy?: string;
    max_continuous_power?: string;
    efficiency?: string;
    price_per_battery?: string;
    battery_additional_charges?: string;
}

const validationSchema = Yup.object().shape({
    manufacturer: Yup.string().required('Manufacturer is required'),
    sku: Yup.string().required('Stock Keeping Unit is required'),
    total_energy: Yup.string().required('Total energy is required').typeError("Total energy is required"),
    max_continuous_power: Yup.string().required('Max continous power is required').typeError("Max continous power is required"),
    efficiency: Yup.string().required('Efficiency is required').typeError("Efficiency is required"),
    price_per_battery: Yup.string().required('Price/Battery is required').typeError("Price/Battery is required"),
    battery_additional_charges: Yup.string().required('Battery additional charge is required').typeError("Battery additional charge is required"),
});

const UpdateBatteryPricing: React.FC<PropsFromRedux> = (props) => {
    // console.log("props", props);
    const { id } = useParams()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    useEffect(() => {
        props.fetchSinglePricing(id);
    }, [id])


    const singleData: SingleBatteryPricingData | undefined = props.fetchSingleRes?.data;
    // console.log("singleData", singleData)

    useEffect(() => {
        if (singleData) {
            const {
                manufacturer = '',
                sku = '',
                total_energy = '',
                max_continuous_power = '',
                efficiency = '',
                price_per_battery = '',
                battery_additional_charges = ''
            } = singleData;
            setValue("manufacturer", manufacturer)
            setValue("sku", sku)
            setValue("total_energy", total_energy)
            setValue("max_continuous_power", max_continuous_power)
            setValue("efficiency", efficiency)
            setValue("price_per_battery", price_per_battery)
            setValue("battery_additional_charges", battery_additional_charges)
        }
    }, [singleData, setValue])

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        props.updatePricing(data, id);
        // console.log("data", data)
    };

    if (props.updateBatteryPriceRes && props.updateBatteryPriceRes.statuscode === 200) {
        alert("Battery Pricing has been updated successfully");
        return <Redirect to="/dashboard/view_battery_pricing" />
    }
    if (props.updateBatteryPriceRes && props.updateBatteryPriceRes.statuscode === 405) {
        alert(props.updateBatteryPriceRes.error);
        window.location.reload();
    }


    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Update Battery Pricing </h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>Update Pricing </span>
                                        </Card.Title>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                            <label htmlFor="title" >Manufacturer</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.manufacturer ? 'is-invalid' : ''}`
                                                    }
                                                    id="manufacture"
                                                    placeholder="Manufacture"
                                                    {...register("manufacturer")}
                                                />
                                                < div className="invalid-feedback"> {errors.manufacturer?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                            <label htmlFor="technology">Stock Keeping Unit (SKU)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.sku ? 'is-invalid' : ''}`}
                                                    id="sku"
                                                    placeholder="Stock Keeping Unit"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("sku")}
                                                />
                                                < div className="invalid-feedback" > {errors.sku?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                                <label htmlFor="total_energy" >Total Energy</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.total_energy ? 'is-invalid' : ''}`}
                                                    id="total_energy"
                                                    placeholder="Total Energy"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("total_energy")}
                                                />
                                                < div className="invalid-feedback" > {errors.total_energy?.message} </div>
                                            </Col>
                                            <Col md={4}>
                                                <label htmlFor="max_continuous_power" >Max Continous Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.max_continuous_power ? 'is-invalid' : ''}`}
                                                    id="max_continuous_power"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Max Continuous Power"
                                                    {...register("max_continuous_power")}
                                                />
                                                < div className="invalid-feedback" > {errors.max_continuous_power?.message} </div>
                                            </Col>
                                            < Col md={4} >
                                                <label htmlFor="efficiency" >Efficiency</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.efficiency ? 'is-invalid' : ''}`}
                                                    id="efficiency"
                                                    placeholder="Efficiency"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("efficiency")}
                                                />
                                                < div className="invalid-feedback" > {errors.efficiency?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="price_per_battery">Price/Battery</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.price_per_battery ? 'is-invalid' : ''}`}
                                                    id="price_per_battery"
                                                    placeholder="Price/Battery"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("price_per_battery")}
                                                />
                                                < div className="invalid-feedback" > {errors.price_per_battery?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="battery_additional_charges">Battery Additional Charge</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.battery_additional_charges ? 'is-invalid' : ''}`}
                                                    id="battery_additional_charges"
                                                    placeholder="Battery Additional Charge"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("battery_additional_charges")}
                                                />
                                                < div className="invalid-feedback" > {errors.battery_additional_charges?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center" >
                                            < Col lg={12} md={12} sm={12} xs={12} className='d-md-flex justify-content-end mt-2' >
                                                {
                                                    props.loading ?
                                                        <button className="lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                                            {("Updating...")}
                                                        </button>
                                                        :
                                                        <button className="lgn-btn" type="submit" > {("UPDATE BATTERY PRICING")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    updateBatteryPriceRes: state.price.updateBatteryPricing,
    fetchSingleRes: state.price.fetchSingleBatteryPricing,

});

const mapDispatchToProps = (dispatch: any) => ({
    updatePricing: (data: FormValues, id: number) => dispatch(updateBatteryPricingAction(data, id)),
    fetchSinglePricing: (id: number) => dispatch(fetchSingleBatteryPricingAction(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UpdateBatteryPricing);
