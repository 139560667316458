import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../reacttable/reactTable.css"
// import moment from "moment";
import Table from "../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchInsightLeadStatusAction } from "../../../store/actions/leadAction";
import Breadcrums from "../Breadcrums";

function InsightLeadCardStatus(props) {
    const { status } = useParams();

    useEffect(() => {
        props.getAllStatusData(status);
    }, [status])

    const data = props.list && props.list.data
    // console.log("data", data);

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Address',
                accessor: 'address'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },
            {
                Header: 'Lead Source',
                accessor: 'leadsource'
            },
            {
                Header: 'Lead Status',
                accessor: 'lead_status'
            },
            // {
            //     Header: "Actions",
            //     accessor: "id",
            //     Cell: (id) => {
            //         return (
            //             <div>
            //                 <span>
            //                     <a
            //                         href={`/dashboard/view_contact/viewSingleContact/${id.value}`}
            //                         rel="tooltip"
            //                         title="View"
            //                     >
            //                         <FaEye />
            //                     </a>
            //                 </span>
            //                 {/* &nbsp;&nbsp;&nbsp;&nbsp;
            //                 <span>
            //                     <a
            //                         href={`/dashboard/updateContact/${id.value}`}
            //                         rel="tooltip"
            //                         title="Edit"
            //                     >
            //                         <FaEdit />
            //                     </a>
            //                 </span> */}
            //             </div>
            //         );
            //     },
            // },
        ],
        []
    );

    return (
        <Container fluid >
            <Breadcrums />
            <Row className="py-5">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Header>
                                <Card.Text>
                                    <h3>All {status} Leads</h3>
                                </Card.Text>
                            </Card.Header>
                            {props.loading ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <h3>Loading...</h3>
                                    <Loader
                                        type="ThreeDots"
                                        color="#232959"
                                        height="40"
                                        width="40"
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    />
                                </div>
                            ) : data && data.length > 0 ? (
                                <Table data={data} columns={columns} />
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        list: state.lead.fetchInsightLeadStatus,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllStatusData: (status) => dispatch(fetchInsightLeadStatusAction(status)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InsightLeadCardStatus);

