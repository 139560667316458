import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CUSTOMER_BASE_URL, PROPOSAL_URL_API } from '../../../config/config';
import axios from 'axios';
import { generateProposalURL } from '../../../../store/actions/customerAction';

export const ProposalSummary = (props) => {
    // const componentRef = useRef();
    const { name, organisation } = JSON.parse(localStorage.getItem('user'))
    const currency = process.env.REACT_APP_CURRENCY;
    // console.log("currency: ", currency)

    // const adminAddress = localStorage.getItem('address');
    const { firstname, lastname, project_title, email, address, panelName, inverterName, batteryName, systemCost, panelQuantity,
        inverterQuantity, batteryQuantity, panelPower, totalPanelPowerr, componentRef
    } = props

    const handleExportAsPDF = () => {
        const input = componentRef.current;
        const padding = 10; // Set padding here

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
            const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * padding;
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = padding;
            pdf.addImage(imgData, 'PNG', padding, position, pdfWidth, imgHeight);

            while (heightLeft >= pdfHeight) {
                position -= pdfHeight;
                heightLeft -= pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', padding, position, pdfWidth, imgHeight);
            }

            pdf.save('export.pdf');
            const pdfBlob = pdf.output('blob');
            // console.log("pdfBlobpdfBlobpdfBlob", pdfBlob)
            uploadToAzure(pdfBlob);
        });
    };

    const uploadToAzure = async (pdfBlob) => {
        // for image start//
        const response = await fetch(localStorage.getItem('imgData'));
        const blob = await response.blob();
        // for image end//

        const formData = new FormData();
        formData.append('image_url', blob, 'captured-image3434.png'); // image url
        formData.append('proposal_url', pdfBlob, 'export343.pdf'); //pdf url
        formData.append('user_email', email); //project id
        formData.append('project_title', project_title); //project id
        const { token } = JSON.parse(localStorage.getItem('user'))
        var bearer = 'basic ' + token;
        const URL = CUSTOMER_BASE_URL + PROPOSAL_URL_API
        await axios.post(URL, formData, {
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'x-ms-blob-type': 'BlockBlob',
            },
        })
            .then(response => {
                // console.log('PDF uploaded successfully:', response.data.data.proposal_url);
                localStorage.setItem("Proposal_URL", response.data.data.proposal_url)
            })
            .catch(error => {
                console.error('Error uploading PDF:', error);
            });
    };


    return (
        <Container fluid >
            <div ref={componentRef}>
                <center>
                    <h4>Proposal For {firstname} {lastname}</h4>
                    <br /> <hr />
                </center>
                <Row>
                    <Col className='text-center'>
                        <p> <b>Prepared By -</b> {name}
                        </p>
                        {organisation}
                    </Col>
                    <Col className='text-center'>
                        <p><b>Prepared For - </b>{firstname} {lastname}</p>
                        {email},<br />
                        {address}
                    </Col>
                </Row>
                <Row>
                    <Col style={{ position: 'relative', textAlign: 'center' }}>
                        <img className='energy' src={process.env.PUBLIC_URL + '/images/proposalSolar.webp'} alt='logo' style={{ width: "100%", height: "410px" }} />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white', // Change the color as needed
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '10px',
                            fontSize: "24px",
                            fontWeight: "bold"
                        }}>
                            Solar Energy System Proposal
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='mt-3'>
                            <b>Dear {firstname}</b>,<br /><br />
                            We are thrilled to welcome you to the solar family! Your decision to opt for solar energy is a significant step towards a sustainable future and a cleaner planet.
                            <br />
                            By choosing solar, you are not only reducing your carbon footprint but also contributing to a greener tomorrow. Your commitment to renewable energy helps in conserving our environment for future generations.
                            <br />
                            We are dedicated to providing you with the best service and support throughout your solar journey. Should you have any questions or need assistance, please do not hesitate to reach out to our team.
                            <br /><br />
                            Thank you once again for making this positive change. Together, we can make a significant impact on our environment.
                        </p>
                        Best Regards,<br />
                        {name}, {organisation}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <center>
                            <h4>Recommended System Design</h4>
                            <br />
                            <hr />
                        </center>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center'>
                        <span style={{ fontSize: "28px" }}>{totalPanelPowerr}kW
                        </span> <br />
                        System Size
                    </Col>
                    <Col className='text-center'>
                        <span style={{ fontSize: "28px" }}>{currency} {systemCost}
                        </span> <br />
                        Total System Price
                    </Col>
                    <Col className='text-center'>
                        <span style={{ fontSize: "28px" }}>{currency} {systemCost}
                        </span> <br />
                        Net System price
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-5'>
                        <img src={`${localStorage.getItem('imgData')}`} style={{ width: "100%" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4'>
                        <center>
                            <h4>Your Solution</h4>
                            <br />
                            <hr />
                        </center>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-2'>
                            <Card.Title>Solar Panel</Card.Title>
                            <Card.Body>
                                <b>{panelName}</b><br />
                                <b>{totalPanelPowerr} kW</b> Total Solar Power <br />
                                <b>{panelQuantity} * {panelPower} Watt Panels</b> <br />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-2'>
                            <Card.Title>Inverter</Card.Title>
                            <Card.Body>
                                <b>{inverterName}</b><br />
                                {/* <b>200.100 kW</b> Total Inverter Power <br /> */}
                                <b>{inverterQuantity}</b>  Quantity <br />
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col>
                        <Card className='p-2'>
                            <Card.Title>Battery</Card.Title>
                            <Card.Body>
                                <b>{batteryName}</b><br />
                                {/* <b>20 </b> Total Battery Rating <br /> */}
                                <b>{batteryQuantity} x </b> Quantity<br />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <Card>
                            <center><Card.Title>Quote Acceptance</Card.Title></center>
                            <Card.Body>
                                I have read & accept the terms and conditions.<br /><br />
                                Signature : __________________________________________________<br /><br />
                                Name : _________________________________ &nbsp;&nbsp;&nbsp; Date : _________________________________

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='logo-css'>
                    <Col className='text-center'>
                        <img className='logofooter' src={process.env.PUBLIC_URL + '/images/auth/c_logo.png'} alt='Gaadin logo' style={{ width: "130px" }} />
                    </Col>
                </Row>
            </div>
            <button className='lgn-btn mt-4' onClick={handleExportAsPDF}>Export as PDF</button>

        </Container>
    )
}

const mapStateToProps = (state) => ({
    proposalurlRes: state.customer.generate_proposal_url,
})

const mapDispatchToProps = (dispatch) => {
    return {
        urlGenerate: (formData) => dispatch(generateProposalURL(formData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSummary)
