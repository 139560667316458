// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .statuscard {
    background: var(--text-secondary);
    border-radius: 10px;
    padding: 15px;
    border-color: transparent;
} */

.cardtext {
    font-weight: 700;
    font-size: 20px;
    color: var(--text-primary);
}

/* .count {
    font-weight: 400;
    font-size: 26px;
    color: var(--text-primary);
} */`, "",{"version":3,"sources":["webpack://./src/components/dashboard/home/projectInsight/projectInsight.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;;;;GAIG","sourcesContent":["/* .statuscard {\n    background: var(--text-secondary);\n    border-radius: 10px;\n    padding: 15px;\n    border-color: transparent;\n} */\n\n.cardtext {\n    font-weight: 700;\n    font-size: 20px;\n    color: var(--text-primary);\n}\n\n/* .count {\n    font-weight: 400;\n    font-size: 26px;\n    color: var(--text-primary);\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
