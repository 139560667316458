import React from 'react';
import Chart from 'react-apexcharts';

const CampaignChart = ({ data, loading }) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // console.log("data from graph", data)


    if (loading) {
        return <div
            // className='d-flex justify-content-center align-items-center'
            style={{ fontSize: "17px", height: "25rem", fontWeight: "600", display: "flex", justifyContent: "center" }}>
            Loading...
        </div>;
    }

    if (!Array.isArray(data)) {
        return <div style={{
            fontSize: "17px",
            height: "25rem",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"

        }}>Error: Data is not an array</div>;
    }

    const options = {
        chart: {
            type: 'bar',
            stacked: false,
        },
        xaxis: {
            categories: data?.map(item => monthNames[item.number - 1])
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
            },
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: 'No. of Campaign / Month',
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#263238'
            }
        },
        yaxis: {
            title: {
                text: 'Number of Emails & SMS Campaigns',
            }
        }
    };

    const series = [
        {
            name: 'Email Sent',
            data: data?.map(item => item.email_sent)
        },
        {
            name: 'SMS Sent',
            data: data?.map(item => item.sms_sent)
        },
    ];

    return (
        <div>
            <Chart options={options} series={series} type="bar" height={350} />
        </div>
    );
};

export default CampaignChart;
