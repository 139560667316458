import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap/esm/Switch";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { logoutAction } from "../../store/actions/authAction";
import { useDispatch } from "react-redux";
import SideToolBar from "./sidetoolbar/toolbar";
import Home from "../dashboard/home/homeCard"
import NewProject from "./home/newProject/createProject.js"
import Settings from "./setting/settings";
import Campaign from "./campaign/campaignCard";
import "../dashboard/layout.css"
import Payment from "./payment/paymentCard";
import Lead from "./home/newProject/viewAllProject.js";
import Customer from "./customer/customerCard";
import ViewPortalUserData from "./admin/accessmanagement/viewAccess.js";
import EditPortalUserData from "./admin/accessmanagement/editAccess.js";
import Admin from "./admin/adminCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import AccessList from "./admin/accessmanagement/accessList.js";
import CreateLead from "./lead/LeadGeneration/createLead.js";
import ViewLead from "./lead/LeadGeneration/viewLead.js";
import ViewSingleLead from "./lead/LeadGeneration/viewSingleLead.js";
import UpdateLead from "./lead/LeadGeneration/updateLead.js";
import CreateContact from "./customer/contact/createContact.js";
import ViewSingleContact from "./customer/contact/viewSingleContact.js";
import UpdateContact from "./customer/contact/updateContact.js";
import CreateEmailCampaign from "./campaign/emailCampaign/createEmailCampaign.js";
import CreateSMSCampaign from "./campaign/smsCampaign/createSMSCampaign.js";
import { GiHamburgerMenu } from "react-icons/gi";
import DownloadProposalList from "./customer/proposal/downloadProposal.js";
import ViewPanelPricing from "./pricing/panel/viewAllPanel.tsx";
import CreatePanelPricing from "./pricing/panel/createPanelPricing.tsx";
import ViewSinglePanelPricing from "./pricing/panel/viewSinglePanelPricing.tsx";
import UpdatePanelPricing from "./pricing/panel/updatePanelPricing.tsx";
import CreateBatteryPricing from "./pricing/battery/createBatteryPricing.tsx";
import ViewAllBatteryPricing from "./pricing/battery/viewAllBatteryPricing.tsx";
import ViewSingleBatteryPricing from "./pricing/battery/viewSingleBatteryPricing.tsx";
import UpdateBatteryPricing from "./pricing/battery/updateBatteryPricing.tsx";
import CreateInverterPricing from "./pricing/inverter/createInverterPricing.tsx"
import ViewAllInverterPricing from "./pricing/inverter/viewAllInverterPricing.tsx"
import ViewSingleInverterPricing from "./pricing/inverter/viewSingleInverterPricing.tsx";
import UpdateInverterPricing from "./pricing/inverter/updateInverterPricing.tsx"
import ViewProjectTransaction from "./customer/projectTransaction/viewProjectTransaction.tsx";
import InsightLeadCardStatus from "./home/insightLeadCardStatus.js";
import InsightProjectCardStatus from "./home/projectInsight/insightProjectStatus.js"
import ViewSingleProject from "./home/newProject/viewSingleProject.js";
import UpdateProject from "./home/newProject/updateProject.js";
import ViewAllProject from "./home/newProject/viewAllProject.js";
import CreateFinance from "./finance/createFinance.tsx";
import ViewAllFinance from "./finance/viewAllFinance.tsx";
import ViewSingleFinance from "./finance/viewSingleFinance.tsx";
import UpdateFinance from "./finance/updateFinance.tsx";

function DashboardLayout(props) {
  const { history, match } = props;
  const { access } = JSON.parse(localStorage.getItem('user'))

  // logic to add id key and sort it based on ID in the access JSON Starts here
  const keyToMapId = {
    'home': 1,
    'lead': 2,
    'pricing': 3,
    'project': 4,
    'customer': 5,
    'finance': 6,
    'campaign': 7,
    'admin': 8,
  };

  const modifiedAccess = access && access.map(item => ({
    ...item,
    id: keyToMapId[item.key] || null
  }));

  const newAccess = modifiedAccess && modifiedAccess.sort((a, b) => a.id - b.id);
  // console.log("newAccess", newAccess)

  // logic to add id key and sort it based on ID in the access JSON Ends here


  const [sideDrawOpen, setIsOpen] = useState(false)
  // const dispatch = useDispatch()
  function drawToggleButtonHandler() {
    setIsOpen(!sideDrawOpen)
  }

  // logic to autoclose the sidebar
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // console.log('resize', window.innerWidth)
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    handleResize();

  }, []);

  const dispatch = useDispatch()
  function LogoutHandler() {
    dispatch(logoutAction())
    history.push("/");
  }
  // console.log("sideDrawOpen", sideDrawOpen)

  return (
    <div className={"wrapper" + (sideDrawOpen ? " toggled" : "")}>
      <header className="toolbar">
        <nav className="toolbar_navigation">
          <div>
            <GiHamburgerMenu color="white" className={`toggle-button  ${sideDrawOpen ? "rotateHamburger" : ""}`} onClick={drawToggleButtonHandler} style={{ fontSize: "30px" }} />
            {/* <button className="toggle-button" onClick={drawToggleButtonHandler}>
            </button> */}
          </div>
          <div className="toolbar_logo">
            <Link to={`${match.path}/${newAccess?.[0]?.subrole?.[0]?.name}`}>
              <img src={process.env.PUBLIC_URL + '/images/auth/gaadin_white_logo.png'} style={{ width: "130px", marginLeft: "25px", marginTop: "18px" }} alt="superpower" className="companylogo" />
            </Link>
          </div>
          <div className="spacer"></div>
          <div className="toolbar_navigation_items">
            <ul className="navbar-right unordered-list">
              <Link to="/dashboard/setting">
                <li className="list-item"> <img src={process.env.PUBLIC_URL + '/images/topbar/Gear.png'} alt="setting" title="setting" /></li>
              </Link>
              <li className="list-item"> <img src={process.env.PUBLIC_URL + '/images/topbar/notification.png'} alt="notification" title="notification" /></li>
              <li className="list-item"> <img src={process.env.PUBLIC_URL + '/images/topbar/logout.png'} alt="Sign Out" title="Sign Out" onClick={LogoutHandler} /></li>
            </ul>
          </div>
        </nav>
      </header>
      <div className="sidebar_wrapper">
        {!sideDrawOpen && <SideToolBar match={match}></SideToolBar>}
      </div>

      <div className={`${!sideDrawOpen ? "main_container" : "main_container_after_toggle"}`}>
        <Switch>
          <Route exact path={`${match.path}`}>
            <Redirect to={`${match.path}/${newAccess?.[0]?.subrole?.[0]?.name}`} />
          </Route>
          <Route exact path={`${match.path}/insight`}>
            <Home></Home>
          </Route>
          <Route exact path={`${match.path}/view_project`}>
            <ViewAllProject></ViewAllProject>
          </Route>
          <Route exact path={`${match.path}/view_contact`}>
            <Customer></Customer>
          </Route>
          <Route exact path={`${match.path}/view_campaign`}>
            <Campaign></Campaign>
          </Route>
          <Route exact path={`${match.path}/payment`}>
            <Payment></Payment>
          </Route>
          <Route exact path={`${match.path}/setting`}>
            <Settings></Settings>
          </Route>
          <Route exact path={`${match.path}/create_access`}>
            <Admin></Admin>
          </Route>
          <Route exact path={`${match.path}/create_project`}>
            <NewProject></NewProject>
          </Route>
          <Route exact path={`${match.path}/view_access/viewportaluser/:userid`}>
            <ViewPortalUserData></ViewPortalUserData>
          </Route>
          <Route exact path={`${match.path}/view_access/editportaluser/:userid`}>
            <EditPortalUserData></EditPortalUserData>
          </Route>
          <Route exact path={`${match.path}/view_project/viewSingleProject/:id`}>
            <ViewSingleProject></ViewSingleProject>
          </Route>
          <Route exact path={`${match.path}/updateProject/:id`}>
            <UpdateProject></UpdateProject>
          </Route>
          <Route exact path={`${match.path}/view_access`}>
            <AccessList></AccessList>
          </Route>
          <Route exact path={`${match.path}/create_lead`}>
            <CreateLead></CreateLead>
          </Route>
          <Route exact path={`${match.path}/view_lead`}>
            <ViewLead></ViewLead>
          </Route>
          <Route exact path={`${match.path}/lead/viewSingleLead/:id`}>
            <ViewSingleLead></ViewSingleLead>
          </Route>
          <Route exact path={`${match.path}/updateLead/:id`}>
            <UpdateLead></UpdateLead>
          </Route>
          <Route exact path={`${match.path}/create_contact`}>
            <CreateContact></CreateContact>
          </Route>
          <Route exact path={`${match.path}/view_contact/viewSingleContact/:id`}>
            <ViewSingleContact></ViewSingleContact>
          </Route>
          <Route exact path={`${match.path}/updateContact/:id`}>
            <UpdateContact></UpdateContact>
          </Route>
          <Route exact path={`${match.path}/add_email_campaign`}>
            <CreateEmailCampaign></CreateEmailCampaign>
          </Route>
          <Route exact path={`${match.path}/add_sms_campaign`}>
            <CreateSMSCampaign></CreateSMSCampaign>
          </Route>
          <Route exact path={`${match.path}/view_proposal_file`}>
            <DownloadProposalList></DownloadProposalList>
          </Route>
          <Route exact path={`${match.path}/create_panel_pricing`}>
            <CreatePanelPricing></CreatePanelPricing>
          </Route>
          <Route exact path={`${match.path}/view_panel_pricing`}>
            <ViewPanelPricing></ViewPanelPricing>
          </Route>
          <Route exact path={`${match.path}/view_panel_pricing/viewSinglePanelPricing/:id`}>
            <ViewSinglePanelPricing></ViewSinglePanelPricing>
          </Route>
          <Route exact path={`${match.path}/updatePanelPricing/:id`}>
            <UpdatePanelPricing></UpdatePanelPricing>
          </Route>
          {/* <Route exact path={`${match.path}/create_proposal`}>
            <CreateProposal></CreateProposal>
          </Route> */}
          <Route exact path={`${match.path}/create_battery_pricing`}>
            <CreateBatteryPricing></CreateBatteryPricing>
          </Route>
          <Route exact path={`${match.path}/view_battery_pricing`}>
            <ViewAllBatteryPricing></ViewAllBatteryPricing>
          </Route>
          <Route exact path={`${match.path}/view_battery_pricing/viewSingleBatteryPricing/:id`}>
            <ViewSingleBatteryPricing></ViewSingleBatteryPricing>
          </Route>
          <Route exact path={`${match.path}/view_battery_pricing/updateBatteryPricing/:id`}>
            <UpdateBatteryPricing></UpdateBatteryPricing>
          </Route>
          <Route exact path={`${match.path}/create_inverter_pricing`}>
            <CreateInverterPricing></CreateInverterPricing>
          </Route>
          <Route exact path={`${match.path}/view_inverter_pricing`}>
            <ViewAllInverterPricing></ViewAllInverterPricing>
          </Route>
          <Route exact path={`${match.path}/view_inverter_pricing/viewSingleInverterPricing/:id`}>
            <ViewSingleInverterPricing></ViewSingleInverterPricing>
          </Route>
          <Route exact path={`${match.path}/view_inverter_pricing/updateInverterPricing/:id`}>
            <UpdateInverterPricing></UpdateInverterPricing>
          </Route>
          <Route exact path={`${match.path}/view_project_transaction`}>
            <ViewProjectTransaction></ViewProjectTransaction>
          </Route>
          <Route exact path={`${match.path}/home/insightLeadCardStatus/:status`}>
            <InsightLeadCardStatus></InsightLeadCardStatus>
          </Route>
          <Route exact path={`${match.path}/home/insightProjectStatus/:status`}>
            <InsightProjectCardStatus></InsightProjectCardStatus>
          </Route>
          {/* // Finance route */}
          <Route exact path={`${match.path}/create_project_finance`}>
            <CreateFinance></CreateFinance>
          </Route>
          <Route exact path={`${match.path}/view_project_finance`}>
            <ViewAllFinance></ViewAllFinance>
          </Route>
          <Route exact path={`${match.path}/view_project_finance/viewSingleFinance/:id`}>
            <ViewSingleFinance></ViewSingleFinance>
          </Route>
          <Route exact path={`${match.path}/updateFinance/:id`}>
            <UpdateFinance></UpdateFinance>
          </Route>
        </Switch>

      </div>

    </div>

  )

}

export default DashboardLayout;