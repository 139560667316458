// This component is preventing input field from entering 0 ,-ve and non-numeric values & characters.

export const HandleKeyPress = (e) => {
    const value = e.target.value;
    const key = e.key;

    // Allow navigation keys (backspace, delete, arrow keys)
    const navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (navigationKeys.includes(key)) {
        return;
    }

    // Allow only one decimal point
    if (key === '.' && value.includes('.')) {
        e.preventDefault();
        return;
    }

    // Prevent entering '.' as the first character
    if (key === '.' && value === '') {
        e.preventDefault();
        return;
    }

    // Prevent entering non-numeric characters
    if (!/^\d$/.test(key) && key !== '-' && key !== '.') {
        e.preventDefault();
        return;
    }

};