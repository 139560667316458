import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { fetchSinglePanelPricingAction } from "../../../../store/actions/modulePricingAction.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";


interface SinglePanelPricingData {
    height?: string;
    manufacturer?: string;
    imp?: string;
    isc?: string;
    panel_additional_charges?: string;
    price_per_module?: string;
    rated_output_power?: string;
    technology?: string;
    thickness?: string;
    vmp?: string;
    voc?: string;
    weight?: string;
    width?: string;
}


const validationSchema = Yup.object().shape({
    manufacture: Yup.string().required('Manufacture is required'),
    technology: Yup.string().required('Technology is required'),
    ratedOutputPower: Yup.string().required('Rated Output Power is required').typeError("Rated Output Power is required"),
    width: Yup.string().required('Width is required').typeError("Width is required"),
    height: Yup.string().required('Height is required').typeError("Height is required"),
    thickness: Yup.string().required('Thickness is required').typeError("Thickness is required"),
    weight: Yup.string().required('Weight is required').typeError("Weight is required"),
    Vmp: Yup.string().required('Vmp is required').typeError("Vmp is required"),
    Voc: Yup.string().required('Voc is required').typeError("Voc is required"),
    Imp: Yup.string().required('Imp is required').typeError("Imp is required"),
    Isc: Yup.string().required('Isc is required').typeError("Isc is required"),
    pricePerModule: Yup.string().required('Price/Moduel is required').typeError("Price/Moduel is required"),
    panelAdditionalCharge: Yup.string().required('Panel additional charge is required').typeError("Panel additional charge is required"),
});

const ViewSinglePanelPricing: React.FC<PropsFromRedux> = (props) => {
    const { id } = useParams()
    // console.log("props", props);

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    useEffect(() => {
        props.fetchSinglePricing(id);
    }, [])

    const singleData: SinglePanelPricingData | undefined = props.fetchSingleRes?.data;
    // console.log("singleData", singleData)

    useEffect(() => {
        if (singleData) {
            const {
                height = '',
                manufacturer = '',
                imp = '',
                isc = '',
                panel_additional_charges = '',
                price_per_module = '',
                rated_output_power = '',
                technology = '',
                thickness = '',
                vmp = '',
                voc = '',
                weight = '',
                width = ''
            } = singleData;
            setValue("manufacture", manufacturer)
            setValue("technology", technology)
            setValue("ratedOutputPower", rated_output_power)
            setValue("height", height)
            setValue("weight", weight)
            setValue("width", width)
            setValue("thickness", thickness)
            setValue("Vmp", vmp)
            setValue("Voc", voc)
            setValue("Imp", imp)
            setValue("Isc", isc)
            setValue("pricePerModule", price_per_module)
            setValue("panelAdditionalCharge", panel_additional_charges)
        }
    }, [singleData, setValue])


    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>View Single Panel Pricing</h4>
                </Col>
            </Row>
            <form  >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>View Single Panel Pricing</span>
                                        </Card.Title>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="title">Manufacture</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `
                                                    }
                                                    id="manufacture"
                                                    placeholder="Manufacture"
                                                    {...register("manufacture")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="technology">Technology</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="technology"
                                                    placeholder="Technology"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("technology")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                                <label htmlFor="ratedOutputPower" >Rated Output Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login`}
                                                    id="ratedOutputPower"
                                                    placeholder="Rated Output Power"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("ratedOutputPower")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <label htmlFor="height" >Height</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="height"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Height"
                                                    {...register("height")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={4} >
                                                <label htmlFor="price_per_battery" >Width</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="width"
                                                    placeholder="Width"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("width")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            < Col md={6} >
                                                <label htmlFor="thickness" >Thickness</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="thickness"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Thickness"
                                                    {...register("thickness")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="weight">Weight</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="weight"
                                                    placeholder="Weight"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("weight")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6} >
                                                <label htmlFor="Vmp">Voltage at Maximum Power (Vmp)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="Vmp"
                                                    placeholder="Voltage at Maximum Power (Vmp)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Vmp")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={6} >
                                                <label htmlFor="Voc">Open-Circuit Voltage (Voc)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="Voc"
                                                    placeholder="Open-Circuit Voltage (Voc)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Voc")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="Imp">Current at Maximum Power (Imp)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="Imp"
                                                    placeholder="Current at Maximum Power (Imp)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Imp")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="Isc" >Short Circuit Current (Isc)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="Isc"
                                                    placeholder="Short Circuit Current (Isc)"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("Isc")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="pricePerModule">Price/Module</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="pricePerModule"
                                                    placeholder="Price/Module"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("pricePerModule")}
                                                    disabled
                                                />
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="panelAdditionalCharge">Panel Additional Charge</label>
                                                < input
                                                    type="text"
                                                    className={`input_login `}
                                                    id="panelAdditionalCharge"
                                                    placeholder="Panel Additional Charge"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("panelAdditionalCharge")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    fetchSingleRes: state.price.fetchSinglePanelPricing,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchSinglePricing: (id: number) => dispatch(fetchSinglePanelPricingAction(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewSinglePanelPricing);
