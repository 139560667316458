import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import moment from "moment";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { getAllContactAction } from "../../../../store/actions/customerAction";

function ConatctList(props) {
    useEffect(() => {
        props.getAllContactData();
    }, [])

    const data = props.list && props.list.data
    // console.log("data", data);

    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_contact/viewSingleContact/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            {/* &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updateContact/${id.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span> */}
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid >

            <div>
                {props.loading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Loading
                        <h3 className="loader">
                            <Loader
                                type="ThreeDots"
                                color="#232959"
                                height="40"
                                width="40"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}
                            />
                        </h3>
                    </div>
                ) : data && data.length > 0 ? (
                    <div>
                        <Table data={data} columns={columns} />
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                        <h3>No Data Found</h3>
                    </div>
                )}
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        list: state.customer.getAllContact,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAllContactData: () => dispatch(getAllContactAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConatctList);

