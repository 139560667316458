import UserApproval from "./userApprovals"
function ApprovalCard(props) {
    return (
        <div>
            <UserApproval />
        </div>
    )

}

export default ApprovalCard