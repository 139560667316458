import React, { useState } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Row, Card } from 'react-bootstrap';
import { forgetPassword } from '../../../store/actions/authAction';
import "./forgetpassword.css"

function ForgetPassword(props) {
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const data = props.forget_password && props.forget_password;
    // console.log(data);
    localStorage.setItem("access", data);

    const toggleNewPassword = () => {
        if (newPasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    const forgetPassSchema = Yup.object().shape({
        newpassword: Yup.string().required('New Password is required').min(6, 'New Password must be at least 6 characters').max(40, 'New Password must not exceed 40 characters'),
        confirmpassword: Yup.string().required('Confirm Password is required').min(6, 'Confirm Password must be at least 6 characters').max(40, 'Confirm Password must not exceed 40 characters')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(forgetPassSchema), mode: "all" });

    const onSubmit = async record => {
        if (record.newpassword !== record.confirmpassword) {
            alert("New password and confirm password do not match");
        } else {
            props.passForget(record);
        }
    };
    // console.log(props.forget_password.access)

    if (props.forget_password.statuscode === 200) {
        alert("Password changed successfully. You will be redirected to the login page.")
        window.location.replace('/');

    }

    return (
        <>
            <div className='login-background'>
                <Card className='confirmpass-box-form'>
                    <Card.Body>
                        <div className='login'>
                            Forget Password ?
                            <br />
                            Don't worry, create a new one
                        </div>
                        <div className='loginform'>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="input_box">
                                    <div className="form-floating">
                                        <input type={newPasswordType} autoFocus className={`login_input  ${errors.newpassword ? 'is-invalid' : ''}`} id="floatingnewpassword" aria-label={"Enter new password"} placeholder={"Enter new password"} name="newpassword" {...register("newpassword")} />
                                        <div className="invalid-feedback">{errors.newpassword?.message}</div>
                                    </div>
                                    <br />
                                    <div className="form-floating">
                                        <input type={confirmPasswordType} className={`login_input  ${errors.confirmpassword ? 'is-invalid' : ''}`} id="floatingconfirmpassword" aria-label={"Enter confirm password"} placeholder={"Enter confirm password"} name="confirmpassword" {...register("confirmpassword")} />
                                        <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                                    </div>

                                    <br /> <br />
                                    <Row className='d-flex justify-content-center' >
                                        <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>Change Password                                        </button>
                                    </Row>
                                </div>
                            </form>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.auth.isLoading,
        forget_password: state.auth.forgetPass,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        passForget: (record) => dispatch(forgetPassword(record)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)