import { boolean } from "yup";
import { CREATE_EMAIL_CAMPAIGN_API, CREATE_LEAD_API, CREATE_SMS_CAMPAIGN_API, FETCH_ALL_EMAIL_CAMPAIGN, FETCH_ALL_SMS_CAMPAIGN, FETCH_CAMPAIGN_GRAPH_API, FETCH_LEAD_GRAPH_API, FETCH_SINGLE_EMAIL_API, FETCH_SINGLE_SMS_API, INSIGHT_LEAD_CARD_COUNT_API, INSIGHT_LEAD_CARD_STATUS_DATA_API, LEAD_BASE_URL, UPDATE_LEAD_API, UPDATE_LEAD_STATUS_API, VALIDATE_LEAD_EMAIL_API, VIEW_ALL_LEAD_API, VIEW_SINGLE_LEAD_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

// Create Lead API
export const createLeadAction = (lead) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("lead data", lead)
    const { firstname, lastname, email, lead_status, countryCode, mobile, address, city, state, country, postal_code, latitude, longitude, description, leadsource } = lead
    // console.log("lead", lead)
    const coordinates = { 'latitude': parseFloat(latitude), 'longitude': parseFloat(longitude) }

    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + CREATE_LEAD_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": parseInt(mobile),
                "address": address,
                "leadsource": leadsource,
                "city": city,
                "state": state,
                "country": country,
                "postal_code": postal_code,
                "coordinates": coordinates,
                "country_code": countryCode,
                "role": role,
                "lead_status": lead_status,
                "operator": organisation,
                "description": description,

            })
        })

        if (data) {
            dispatch({ type: 'CREATE_NEW_LEAD', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// update lead api
export const updateLeadAction = (lead, id) => {
    const { token, role, organisation, userid } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("lead data", lead)
    const { firstname, lastname, email, leadsource, lead_status, countryCode, mobile, address, city, state, country, postal_code, latitude, longitude, description } = lead
    const coordinates = { 'latitude': parseFloat(latitude), 'longitude': parseFloat(longitude) };
    // Get today's date in the required format
    const today = new Date();
    const updatedAt = today.toISOString(); // This gives the format "YYYY-MM-DDTHH:MM:SSZ"
    const leadUpdate = [
        {
            updated_by: userid,
            updatedat: updatedAt
        }
    ];
    // console.log("lead_update", leadUpdate)

    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + UPDATE_LEAD_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": parseInt(mobile),
                "address": address,
                "contact_source": "Lead",
                "city": city,
                "state": state,
                "country": country,
                "postal_code": postal_code,
                "coordinates": coordinates,
                "leadsource": leadsource,
                "country_code": countryCode,
                "role": role,
                "lead_status": lead_status,
                "operator": organisation,
                "description": description,
                "lead_update": leadUpdate

            })
        })

        if (data) {
            dispatch({ type: 'UPDATE_LEAD_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// update lead Status action api
export const updateLeadStatusAction = (lead_id, lead_status) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + UPDATE_LEAD_STATUS_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "lead_id": lead_id,
                "lead_status": lead_status,
            })
        })

        if (data) {
            dispatch({ type: 'UPDATE_LEAD_STATUS_ACTION', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// View All Lead API
export const fetchAllLeadAction = () => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("user", JSON.parse(localStorage.getItem('user')))
    // console.log("token", token)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + VIEW_ALL_LEAD_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_LEAD', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// view single Lead API
export const fetchSingleLeadAction = (id) => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + VIEW_SINGLE_LEAD_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_SINGLE_LEAD', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// validate email api
export const validateLeadEmailAction = (id) => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + VALIDATE_LEAD_EMAIL_API + "?email=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'VALIDATE_LEAD_EMAIL_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

//  Fetch lead graph api
export const fetchLeadGraphAction = () => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_LEAD_GRAPH_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_LEAD_GRAPH_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// Create Email campaign api 
export const createEmailCampaignAction = (data, startDate) => {
    const { token, name, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { recipients, subject, message } = data

    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + CREATE_EMAIL_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "recipients": recipients,
                "subject": subject,
                "date": startDate,
                "message": message,
                "role": role,
                "organisation": organisation,
            })
        })

        if (data) {
            dispatch({ type: 'CREATE_EMAIL_CAMPAIGN_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// Create SMS campaign api 
export const createSMSCampaignAction = (data, startDate) => {
    const { token, name, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { recipients, title, message } = data

    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + CREATE_SMS_CAMPAIGN_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "recipients": recipients,
                "title": title,
                "date": startDate,
                "message": message,
                "role": role,
                "organisation": organisation,
            })
        })

        if (data) {
            dispatch({ type: 'CREATE_SMS_CAMPAIGN_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH all email api 
export const fetchAllEmailAction = () => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_ALL_EMAIL_CAMPAIGN + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_EMAIL_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH all SMS api 
export const fetchAllSmsAction = () => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_ALL_SMS_CAMPAIGN + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_SMS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH Single Email api 
export const fetchSingleEmailAction = (id) => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_SINGLE_EMAIL_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_SINGLE_EMAIL_API', payload: data })
        } else {
            console.log("No data found from server")
        }

    }
}

// FETCH Single SMS api 
export const fetchSingleSmsAction = (id) => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_SINGLE_SMS_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_SINGLE_SMS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH Campaign Graph api 
export const fetchCampaignGraphAction = () => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_CAMPAIGN_GRAPH_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_CAMPAIGN_GRAPH_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// INSIGHT LEAD COUNT API
export const fetchInsightLeadCountApi = () => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + INSIGHT_LEAD_CARD_COUNT_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_INSIGHT_LEAD_COUNT_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// INSIGHT LEAD STATUS DATA API
export const fetchInsightLeadStatusAction = (status) => {
    const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("dadwada", JSON.parse(localStorage.getItem('user')))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'LEAD_LOADING', payload: '' })
        // console.log(LEAD_BASE_URL + INSIGHT_LEAD_CARD_STATUS_DATA_API + "?role=" + role + "&status=" + status)
        const data = await ApiHandler(LEAD_BASE_URL + INSIGHT_LEAD_CARD_STATUS_DATA_API + "?role=" + role + "&status=" + status, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })

        if (data) {
            dispatch({ type: 'FETCH_INSIGHT_LEAD_STATUS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}