import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import ProjectList from "./projectList";
import { fetchAllProjectApi } from "../../../../store/actions/projectAction";
import "./createProject.css";
import { fetchProjectGraphAction } from "../../../../store/actions/customerAction";
// import ProjectStatusChart from "../home/projectGraph";

export const options = {
    responsive: true,
    plugins: {
        //   legend: {
        //     position: 'top' as const,
        //   },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'];

export const data = {
    labels,
    datasets: [
        {
            label: 'New Lead',
            data: labels.map(() => Math.random() * 10000),
            backgroundColor: 'rgba(14, 191, 204, 1)',
        },
        {
            label: 'In Progress',
            data: labels.map(() => Math.random() * 10000),
            backgroundColor: 'rgba(134, 212, 66, 1)',
        },
        {
            label: 'Completed',
            data: labels.map(() => Math.random() * 10000),
            backgroundColor: 'rgba(15, 22, 16, 1)',
        },
    ],
};


function ViewAllProject(props) {
    useEffect(() => {
        props.fetchAllProject();
        props.fetchProjectGraph();
    }, [])

    const projectGraphData = props.projectGraphDataRes && props.projectGraphDataRes.data;
    const projectData = props.list && props.list?.data;
    // console.log(projectGraphData)
    const totalProject = projectData && projectData.length
    const newProject = projectData && projectData.filter(en => en.status === "New").length
    const inprogressProject = projectData && projectData.filter(en => en.status === "In Progress").length
    const completedProject = projectData && projectData.filter(en => en.status === "Completed").length
    const lostProject = projectData && projectData.filter(en => en.status === "Lost").length

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-3 col-sm-12 mt-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> Total Project </span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}> {totalProject !== null ? totalProject : 0}
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">New Project</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}> {newProject !== null ? newProject : 0}
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">Proposal Sent</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}> {inprogressProject !== null ? inprogressProject : 0}
                        </span>
                    </Card>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext">Completed Project </span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}> {completedProject !== null ? completedProject : 0}
                        </span>
                    </Card>
                </div>

            </div>
            {/* 
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b>  <div className="text-left">This Year Growth</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            <ProjectStatusChart data={projectGraphData} loading={props.projectGraphLoading} />
                        </div>
                    </Card>
                </div>
            </div> */}

            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">Project List</div></b>
                                </Col>
                                <Col xs={12} md={4}>
                                    <b><div className="text-right">Total Projects : {projectData && projectData.length ? projectData.length : 0}</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div>
                            <ProjectList />
                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        projectGraphLoading: state.customer.isLoading,
        projectGraphDataRes: state.customer.fetchProjectGraph,
        list: state.project.fetchAllProject
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllProject: () => dispatch(fetchAllProjectApi()),
        fetchProjectGraph: () => dispatch(fetchProjectGraphAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAllProject);