import React, { useState } from 'react';
import { connect } from "react-redux";
import "./login.css";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Row, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { authAction } from '../../../store/actions/authAction';

function AuthComponent(props) {
    const [passwordType, setPasswordType] = useState("password");

    const loginSchema = Yup.object().shape({
        userid: Yup.string().required('Username is required').min(6, 'Username must be at least 6 characters').max(20, 'Username must not exceed 20 characters'),
        password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters')
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema), mode: "all" });

    const onSubmit = async (data) => {
        // console.log("data.userid, data.password", data.userid, data.password)
        props.signIn(data.userid, data.password);
    };

    if (props.authresponse.statuscode === 200 && (props.authresponse.ispasswordupdated == false)) {
        return <Redirect to='/changepassword' />;
    }
    if (props.authresponse && props.authresponse.statuscode === 200 && (props.authresponse.ispasswordupdated && props.authresponse.error === '')) {
        return <Redirect to='/dashboard/insight' />;
    }
    // console.log(props.authresponse)

    if (props.authresponse && props.authresponse.statuscode === 404) {
        alert(props.authresponse.error);
        window.location.reload();
    }

    return (
        <div className='login-background'>
            <Card className='box-form'>
                <Card.Body>
                    <Row className="d-flex justify-content-center">
                        <span className="login">
                            LOGIN
                            <div className="green-line"></div>
                        </span>
                    </Row>
                    <br />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input_box">
                            <div className="form-floating mb-4 input-icons">
                                <i className={`fa fa-user ${errors.userid ? 'after_icon' : 'icon'}`}></i>
                                <input
                                    type="text"
                                    autoFocus
                                    className={`login_input ${errors.userid ? 'is-invalid' : ''}`}
                                    id="floatingloginInputuserid"
                                    name="userid"
                                    placeholder="Enter Username"
                                    aria-label="Enter userid"
                                    {...register("userid")}
                                />
                                <div className="invalid-feedback">{errors.userid?.message}</div>
                            </div>

                            <div className="form-floating input-icons">
                                <i className={`fa fa-lock ${errors.password ? 'after_icon' : 'icon'}`}></i>
                                <input
                                    type={passwordType}
                                    className={`login_input ${errors.password ? 'is-invalid' : ''}`}
                                    id="floatingloginInputPassword"
                                    placeholder="**************"
                                    name="password"
                                    aria-label="Enter Password"
                                    {...register("password")}
                                />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className='mt-3 d-flex justify-content-end '>
                                <a href="/forget_otp" className="forgetpass_style">Forget Password?</a>
                            </div>
                            <br />
                            <Row className='d-flex justify-content-center'>
                                {props.loading ?
                                    <button className="lgn-btn" disabled type="submit">
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {("Loading...")}
                                    </button>
                                    :
                                    <button className="lgn-btn" type="submit">Login</button>
                                }
                            </Row>
                            <Row className='logo-css'>
                                <img className='logofooter' src={process.env.PUBLIC_URL + '/images/auth/c_logo.png'} alt='Gaadin logo' />
                            </Row>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.isLoading,
        authresponse: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (userid, password) => dispatch(authAction(userid, password)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
