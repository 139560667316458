let user = JSON.parse(localStorage.getItem('user'));
const initState = {
  user: user ? { user } : {},
  isLoading: false,
  isLogged: false,
  forgetPass: '',
  getSendOTP: '',
  getReSendOTP: '',
  validateOTP: '',
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'AUTH_LOADING':
      return { ...state, isLoading: true }
    case 'LOGIN_USER':
      return { ...state, user: action.payload, isLoading: false, isLogged: true }
    case 'FORGET_USER_PASSWORD':
      return { ...state, forgetPass: action.payload, isLoading: false };
    case 'GET_SEND_OTP':
      return { ...state, getSendOTP: action.payload, isLoading: false };
    case 'GET_RESEND_OTP':
      return { ...state, getReSendOTP: action.payload, isLoading: false };
    case 'VALIDATE_USER_OTP':
      return { ...state, validateOTP: action.payload, isLoading: false };
    case 'LOGOUT_USER':
      return { ...state, user: {} }
    default:
      return state;
  }
}
export default authReducer;