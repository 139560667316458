const initState = {
    isLoading: false,
    createPanelPricing: '',
    fetchAllPanelPricing: '',
    fetchSinglePanelPricing: '',
    updatePanelPricing: '',
    deletePanelPricing: '',
    createBatteryPricing: '',
    fetchAllBatteryPricing: '',
    fetchSingleBatteryPricing: '',
    updateBatteryPricing: '',
    deleteBatteryPricing: '',
    createInverterPricing: '',
    fetchAllInverterPricing: '',
    fetchSingleInverterPricing: '',
    updateInverterPricing: '',
    deleteInverterPricing: '',
    fetchSinglePanelDetails: '',
    fetchSingleInverterDetails: '',
    fetchSingleBatteryDetails: '',
}

const pricingReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PRICING_LOADING':
            return { ...state, isLoading: true }
        case 'CREATE_PANEL_PRICING_API':
            return { ...state, createPanelPricing: action.payload, isLoading: false }
        case 'FETCH_ALL_PANEL_PRICING_API':
            return { ...state, fetchAllPanelPricing: action.payload, isLoading: false }
        case 'FETCH_SINGLE_PANEL_PRICING_API':
            return { ...state, fetchSinglePanelPricing: action.payload, isLoading: false }
        case 'UPDATE_PANEL_PRICING_API':
            return { ...state, updatePanelPricing: action.payload, isLoading: false }
        case 'DELETE_PANEL_PRICING_API':
            return { ...state, deletePanelPricing: action.payload, isLoading: false }
        case 'CREATE_BATTERY_PRICING_API':
            return { ...state, createBatteryPricing: action.payload, isLoading: false }
        case 'FETCH_ALL_BATTERY_PRICING_API':
            return { ...state, fetchAllBatteryPricing: action.payload, isLoading: false }
        case 'DELETE_BATTERY_PRICING_API':
            return { ...state, deleteBatteryPricing: action.payload, isLoading: false }
        case 'FETCH_SINGLE_BATTERY_PRICING_API':
            return { ...state, fetchSingleBatteryPricing: action.payload, isLoading: false }
        case 'UPDATE_BATTERY_PRICING_API':
            return { ...state, updateBatteryPricing: action.payload, isLoading: false }
        case 'CREATE_INVERTER_PRICING_API':
            return { ...state, createInverterPricing: action.payload, isLoading: false }
        case 'FETCH_ALL_INVERTER_PRICING_API':
            return { ...state, fetchAllInverterPricing: action.payload, isLoading: false }
        case 'DELETE_INVERTER_PRICING_API':
            return { ...state, deleteInverterPricing: action.payload, isLoading: false }
        case 'FETCH_SINGLE_INVERTER_PRICING_API':
            return { ...state, fetchSingleInverterPricing: action.payload, isLoading: false }
        case 'UPDATE_INVERTER_PRICING_API':
            return { ...state, updateInverterPricing: action.payload, isLoading: false }
        case 'FETCH_SINGLE_PANEL_DETAILS_API':
            return { ...state, fetchSinglePanelDetails: action.payload, isLoading: false }
        case 'FETCH_SINGLE_INVERTER_DETAILS_API':
            return { ...state, fetchSingleInverterDetails: action.payload, isLoading: false }
        case 'FETCH_SINGLE_BATTERY_DETAILS_API':
            return { ...state, fetchSingleBatteryDetails: action.payload, isLoading: false }
        default:
            return state;
    }
}

export default pricingReducer;