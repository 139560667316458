// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerCard {
    width: 100%;
    height: 122px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000D;
    border-radius: 10px;
    border-color: #ffffff;
}

.innercard {
    top: 20px;
    left: 45px;
    text-align: center;
    width: 192px;
    height: 83px;
    background: #1A1A1A 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 25px #0000001A;
    border-radius: 10px;
    color: #ffffff;
}

.graphcard {
    width: 100%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border-color: #ffffff;

}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/campaign/campaignCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,+CAA+C;IAC/C,iCAAiC;IACjC,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,+CAA+C;IAC/C,kCAAkC;IAClC,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,+CAA+C;IAC/C,mBAAmB;IACnB,qBAAqB;;AAEzB","sourcesContent":[".headerCard {\n    width: 100%;\n    height: 122px;\n    background: #ffffff 0% 0% no-repeat padding-box;\n    box-shadow: 0px 3px 6px #0000000D;\n    border-radius: 10px;\n    border-color: #ffffff;\n}\n\n.innercard {\n    top: 20px;\n    left: 45px;\n    text-align: center;\n    width: 192px;\n    height: 83px;\n    background: #1A1A1A 0% 0% no-repeat padding-box;\n    box-shadow: 0px 3px 25px #0000001A;\n    border-radius: 10px;\n    color: #ffffff;\n}\n\n.graphcard {\n    width: 100%;\n    height: 100%;\n    background: #FFFFFF 0% 0% no-repeat padding-box;\n    border-radius: 10px;\n    border-color: #ffffff;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
