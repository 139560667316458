import React from "react";
import PureBreadcrumbs from "../Breadcrums"
import AccessManagement from "./accessmanagement/accessManagement";
import { Container, Row, Col, Card } from 'react-bootstrap';
import "./adminCard.css"

function Admin(props) {    return (
        <Container fluid>
            <Row>
                <Col className="mt-4">
                    <PureBreadcrumbs />
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <Card className="tariffcard">
                        <Card.Title>Create Access Management</Card.Title>
                        <Card.Body>
                            <AccessManagement />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>

    )


}

export default Admin;