import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Row, Card, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "./login/login.css"
import { updateUserPasswordAction } from '../../store/actions/accessAction';

function ChangePassword(props) {
    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string().required('Confirm Password is required').min(6, 'Confirm Password must be at least 6 characters').max(40, 'Confirm Password must not exceed 40 characters'),
    });
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(changePasswordSchema), mode: "all" });

    const onSubmit = (record) => {
        props.updatePassData(record)
    };

    useEffect(() => {
        if (props.response.statuscode === 405) {
            alert(props.response.error)
        }
        if (props.response.statuscode === 200 && props.response.ispasswordupdated === true) {
            alert(props.response.result)
            // window.location.replace('/dashboard');
            window.location.replace('/');
        }

    }, [props.response])

    return (

        <div className='login-background'>
            <Card className='changepassbox-form'>
                <Card.Body>
                    <Row className='d-flex justify-content-center'>
                        <span className='login'>
                            Change your password
                        </span>
                    </Row>
                    <br />
                    <br />
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="form-floating input-icons">
                            <i className={`fa fa-lock ${errors.password ? 'after_icon' : 'icon'}`}></i>
                            <input
                                type="password"
                                className={`login_input ${errors.password ? 'is-invalid' : ''}`}
                                id="floatingloginInputPassword"
                                placeholder="Enter the new password"
                                name="password"
                                aria-label="Enter Password"
                                {...register("password")}
                            />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <br />
                        <div className="form-floating input-icons">
                            <i className={`fa fa-lock ${errors.password ? 'after_icon' : 'icon'}`}></i>
                            <input
                                type="password"
                                className={`login_input ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                id="floatingloginInputConfirmPassword"
                                placeholder="Re-enter the new password"
                                name="confirmPassword"
                                aria-label="Re-enter the new password"
                                {...register("confirmPassword")}
                            />
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                        </div>
                        <br />
                        <br />
                        <Row className='d-flex justify-content-center'>
                            {props.loading ?
                                <button className="lgn-btn" type="button" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;
                                    {("Saving...")}
                                </button>
                                :
                                <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>Update Password</button>
                            }
                        </Row>
                        <Row className='logo-css'>
                            <img className='logofooter' src={process.env.PUBLIC_URL + '/images/auth/c_logo.png'} alt='Gaadin logo' />
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>

    )


}

const mapStateToProps = (state) => {
    return {
        loading: state.access.isLoading,
        response: state.access.changePassword,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updatePassData: (record) => dispatch(updateUserPasswordAction(record))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
