import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { createBatteryPricingAction, createPanelPricingAction } from "../../../../store/actions/modulePricingAction.js";

interface FormValues {
    manufacture: string;
    sku: string;
    total_energy: string;
    max_continuous_power: string;
    efficiency: string;
    price_per_battery: string;
    battery_additional_charges: string;
}

const validationSchema = Yup.object().shape({
    manufacture: Yup.string().required('Manufacturer is required'),
    sku: Yup.string().required('Stock Keeping Unit is required'),
    total_energy: Yup.string().required('Total energy is required').typeError("Total energy is required"),
    max_continuous_power: Yup.string().required('Max continuous power is required').typeError("Max continuous power is required"),
    efficiency: Yup.string().required('Efficiency is required').typeError("Efficiency is required"),
    price_per_battery: Yup.string().required('Price/Battery is required').typeError("Price/Battery is required"),
    battery_additional_charges: Yup.string().required('Battery additional charge is required').typeError("Battery additional charge is required"),
});

const CreateBatteryPricing: React.FC<PropsFromRedux> = (props) => {
    // console.log("props", props);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        props.saveBatteryPricing(data);
        // console.log("data", data)
    };

    useEffect(() => {
        if (props.saveBatteryPricingRes && props.saveBatteryPricingRes.statuscode === 200) {
            alert("Battery Pricing has been created successfully");
            window.location.reload();
        }
        if (props.saveBatteryPricingRes && props.saveBatteryPricingRes.statuscode === 405) {
            alert(props.saveBatteryPricingRes.error);
            window.location.reload();
        }
    }, [props.saveBatteryPricingRes]);

    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Create Battery Pricing </h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Card.Title>
                                            <span>Create Pricing </span>
                                        </Card.Title>

                                        <Row className="mt-3" >
                                            <Col md={6}>
                                                <label htmlFor="title" >Manufacturer</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.manufacture ? 'is-invalid' : ''}`
                                                    }
                                                    id="manufacture"
                                                    placeholder="Manufacture"
                                                    {...register("manufacture")}
                                                />
                                                < div className="invalid-feedback"> {errors.manufacture?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="technology">Stock Keeping Unit (SKU)</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.sku ? 'is-invalid' : ''}`}
                                                    id="sku"
                                                    placeholder="Stock Keeping Unit"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("sku")}
                                                />
                                                < div className="invalid-feedback" > {errors.sku?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                                <label htmlFor="total_energy" >Total Energy</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.total_energy ? 'is-invalid' : ''}`}
                                                    id="total_energy"
                                                    placeholder="Total Energy"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("total_energy")}
                                                />
                                                < div className="invalid-feedback" > {errors.total_energy?.message} </div>
                                            </Col>
                                            <Col md={4}>
                                                <label htmlFor="max_continuous_power" >Max Continous Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.max_continuous_power ? 'is-invalid' : ''}`}
                                                    id="max_continuous_power"
                                                    onKeyDown={HandleKeyPress}
                                                    placeholder="Max Continuous Power"
                                                    {...register("max_continuous_power")}
                                                />
                                                < div className="invalid-feedback" > {errors.max_continuous_power?.message} </div>
                                            </Col>
                                            < Col md={4} >
                                                <label htmlFor="efficiency" >Efficiency</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.efficiency ? 'is-invalid' : ''}`}
                                                    id="efficiency"
                                                    placeholder="Efficiency"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("efficiency")}
                                                />
                                                < div className="invalid-feedback" > {errors.efficiency?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="price_per_battery">Price/Battery</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.price_per_battery ? 'is-invalid' : ''}`}
                                                    id="price_per_battery"
                                                    placeholder="Price/Battery"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("price_per_battery")}
                                                />
                                                < div className="invalid-feedback" > {errors.price_per_battery?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="battery_additional_charges">Battery Additional Charge</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.battery_additional_charges ? 'is-invalid' : ''}`}
                                                    id="battery_additional_charges"
                                                    placeholder="Battery Additional Charge"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("battery_additional_charges")}
                                                />
                                                < div className="invalid-feedback" > {errors.battery_additional_charges?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center" >
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start" >
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    reset();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            < Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2' >
                                                {
                                                    props.loading ?
                                                        <button className="lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                                            {("Saving...")}
                                                        </button>
                                                        :
                                                        <button className="lgn-btn" type="submit" > {("CREATE BATTERY PRICING")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    saveBatteryPricingRes: state.price.createBatteryPricing,
});

const mapDispatchToProps = (dispatch: any) => ({
    saveBatteryPricing: (data: FormValues) => dispatch(createBatteryPricingAction(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateBatteryPricing);
