import React from "react";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../Breadcrums"
import PaymentList from "./paymentlist/list";
import "./paymentCard.css"


function Payment(props) {
    return (
        <Container fluid >
            <Row>
                <Col className="mt-4">
                    <PureBreadcrumbs />
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="headerCard">
                        <Row>
                            <Col xs={12} md={3} >
                                <Card className="innercard">
                                    <div style={{ color: "#FFB400" }}>
                                        <span>
                                            Available Amount
                                        </span>
                                        <span>
                                            <h2>$25k</h2>
                                        </span>
                                    </div>

                                </Card>
                            </Col>
                            <Col xs={12} md={3}>
                                <Card className="innercard">
                                    <div style={{ color: "#0090FF" }}>
                                        <span>
                                            Spending Amount
                                        </span>
                                        <span>
                                            <h2>$10k</h2>
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={3}>
                                <Card className="innercard">
                                    <div style={{ color: "#80FE13" }}>
                                        <span>
                                            Last 30 days
                                        </span>
                                        <span>
                                            <h2>$3000</h2>
                                        </span>
                                    </div>
                                </Card>

                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Card className="innercard">
                                    <div style={{ color: "#FF00EE" }}>
                                        <span>
                                            Refund Amount
                                        </span>
                                        <span>
                                            <h2>$500</h2>
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="paymentcard">
                        <Row>
                            <Col className="mt-2">
                                <b> User Details</b>
                            </Col>
                            <Col className="mt-2" style={{ textAlign: "right" }}>
                                <a href={`/dashboard/customer`}  >
                                    <Button style={{ width: "156px", height: "38px", borderRadius: "5px" }}>View All</Button>{' '}
                                </a>
                            </Col>
                        </Row>
                        <div>
                            <PaymentList />
                        </div>

                    </Card>
                </Col>
            </Row>
            <br />
            <br />

        </Container>
    )

}

export default Payment

