import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums.js";
import "../pricing.css";
import { HandleKeyPress } from "../../reusableComponent/handleKeyPress";
import { createInverterPricingAction } from "../../../../store/actions/modulePricingAction.js";

interface FormValues {
    manufacturer: string,
    efficiency: string,
    technology: string,
    rated_output_power: string,
    rated_output_voltage: string,
    number_of_mppts: string,
    maximum_vmpp: string,
    minimum_vmpp: string,
    phase: string,
    max_ac_output_current: string,
    price_per_inverter: string,
    inverter_additional_charges: string
}

const validationSchema = Yup.object().shape({
    manufacturer: Yup.string().required('Manufacturer is required'),
    efficiency: Yup.string().required('Efficiency is required').typeError("Efficiency is required"),
    technology: Yup.string().required('Technology is required'),
    rated_output_power: Yup.string().required('Rated Output Power is required').typeError("Rated Output Power is required"),
    rated_output_voltage: Yup.string().required('Rated Output Voltage is required').typeError("Rated Output Voltage is required"),
    number_of_mppts: Yup.string().required('Number of MPPTS is required').typeError("Number of MPPTS is required"),
    maximum_vmpp: Yup.string().required('Maximum VMPP is required').typeError("Maximum VMPP is required"),
    minimum_vmpp: Yup.string().required('Minimum VMPP is required').typeError("Minimum VMPP is required"),
    phase: Yup.string().required('Phase is required').typeError("Phase is required"),
    max_ac_output_current: Yup.string().required('Max Ac Output Current is required').typeError("Max Ac Output Current is required"),
    price_per_inverter: Yup.string().required('Inverter Price is required').typeError("Inverter Price is required"),
    inverter_additional_charges: Yup.string().required('Additional Price is required').typeError("Additional Price is required"),
});

const CreateInverterPricing: React.FC<PropsFromRedux> = (props) => {
    // console.log("props", props);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        props.saveInverterPricing(data);
        // console.log("data", data)
    };

    useEffect(() => {
        if (props.saveInverterPricingRes && props.saveInverterPricingRes.statuscode === 200) {
            alert("Inverter Pricing has been created successfully");
            window.location.reload();
        }
        if (props.saveInverterPricingRes && props.saveInverterPricingRes.statuscode === 405) {
            alert(props.saveInverterPricingRes.error);
            window.location.reload();
        }
    }, [props.saveInverterPricingRes]);

    return (
        <Container fluid >
            <div className="row" >
                <div className="col-lg-6 col-sm-12 mt-2" >
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row Row className="mb-2" >
                <Col className="mt-3" >
                    <h4>Create Inverter Pricing </h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Row className="d-flex justify-content-center" >
                    <Col lg={10}>
                        <Card className="tariffcard" >
                            <Card.Body>
                                <Row className="py-2" >
                                    <Col>
                                        <Row className="mt-0" >
                                            <Col md={6}>
                                                <label htmlFor="title" >Manufacturer</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.manufacturer ? 'is-invalid' : ''}`
                                                    }
                                                    id="manufacturer"
                                                    placeholder="Manufacturer Name"
                                                    {...register("manufacturer")}
                                                />
                                                < div className="invalid-feedback"> {errors.manufacturer?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="efficiency" >Efficiency</label>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.efficiency ? 'is-invalid' : ''}`}
                                                    id="efficiency"
                                                    placeholder="Efficiency"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("efficiency")}
                                                />
                                                < div className="invalid-feedback" > {errors.efficiency?.message} </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3" >
                                            <Col md={4}>
                                                <label htmlFor="total_energy" >Technology</label>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.technology ? 'is-invalid' : ''}`}
                                                    id="technology"
                                                    placeholder="Technology"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("technology")}
                                                />
                                                < div className="invalid-feedback" > {errors.technology?.message} </div>
                                            </Col>
                                            <Col md={4}>
                                                <label htmlFor="max_continuous_power" >Rated Output Power</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.rated_output_power ? 'is-invalid' : ''}`}
                                                    id="rated_output_power"
                                                    // onKeyDown={HandleKeyPress}
                                                    placeholder="Rated Output Power"
                                                    {...register("rated_output_power")}
                                                />
                                                < div className="invalid-feedback" > {errors.rated_output_power?.message} </div>
                                            </Col>
                                            < Col md={4} >
                                                <label htmlFor="efficiency" >Rated Output Voltage</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.rated_output_voltage ? 'is-invalid' : ''}`}
                                                    id="rated_output_voltage"
                                                    placeholder="Rated Output Voltage"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("rated_output_voltage")}
                                                />
                                                < div className="invalid-feedback" > {errors.rated_output_voltage?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="number_of_mppts">No of MPPTS</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.number_of_mppts ? 'is-invalid' : ''}`}
                                                    id="number_of_mppts"
                                                    placeholder="No of MPPTS"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("number_of_mppts")}
                                                />
                                                < div className="invalid-feedback" > {errors.number_of_mppts?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="battery_additional_charges">Minimum VMPP</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.minimum_vmpp ? 'is-invalid' : ''}`}
                                                    id="minimum_vmpp"
                                                    placeholder="Minimum VMPP"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("minimum_vmpp")}
                                                />
                                                < div className="invalid-feedback" > {errors.minimum_vmpp?.message} </div>
                                            </Col>
                                        </Row>

                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="number_of_mppts">Maximum VMPP</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.maximum_vmpp ? 'is-invalid' : ''}`}
                                                    id="maximum_vmpp"
                                                    placeholder="Maximum VMPP"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("maximum_vmpp")}
                                                />
                                                < div className="invalid-feedback" > {errors.maximum_vmpp?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="battery_additional_charges">Phase</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.phase ? 'is-invalid' : ''}`}
                                                    id="phase"
                                                    placeholder="Phase"
                                                    // onKeyDown={HandleKeyPress}
                                                    {...register("phase")}
                                                />
                                                < div className="invalid-feedback" > {errors.phase?.message} </div>
                                            </Col>
                                        </Row>
                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="number_of_mppts">Max AC Outpur Current</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.max_ac_output_current ? 'is-invalid' : ''}`}
                                                    id="max_ac_output_current"
                                                    placeholder="Max AC Outpur Current"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("max_ac_output_current")}
                                                />
                                                < div className="invalid-feedback" > {errors.max_ac_output_current?.message} </div>
                                            </Col>
                                            < Col md={6} >
                                                <label htmlFor="battery_additional_charges">Price Per Inverter</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.price_per_inverter ? 'is-invalid' : ''}`}
                                                    id="price_per_inverter"
                                                    placeholder="Price Per Inverter"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("price_per_inverter")}
                                                />
                                                < div className="invalid-feedback" > {errors.price_per_inverter?.message} </div>
                                            </Col>
                                        </Row>
                                        < Row className="mt-3" >
                                            < Col md={6}>
                                                <label htmlFor="number_of_mppts">Inverter Additional Charger</label>
                                                < input
                                                    type="text"
                                                    className={`input_login ${errors.inverter_additional_charges ? 'is-invalid' : ''}`}
                                                    id="inverter_additional_charges"
                                                    placeholder="Inverter Additional Charger"
                                                    onKeyDown={HandleKeyPress}
                                                    {...register("inverter_additional_charges")}
                                                />
                                                < div className="invalid-feedback" > {errors.inverter_additional_charges?.message} </div>
                                            </Col>

                                        </Row>

                                        <Row className="mt-3 d-flex justify-content-sm-center" >
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start" >
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    reset();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            < Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2' >
                                                {
                                                    props.loading ?
                                                        <button className="lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                                            {("Saving...")}
                                                        </button>
                                                        :
                                                        <button className="lgn-btn" type="submit" > {("CREATE INVERTER PRICING")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.price.isLoading,
    saveInverterPricingRes: state.price.createInverterPricing,
});

const mapDispatchToProps = (dispatch: any) => ({
    saveInverterPricing: (data: FormValues) => dispatch(createInverterPricingAction(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateInverterPricing);
